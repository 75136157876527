import React from "react"
import { motion as m } from "framer-motion"

const Card = ({ data, className, i }) => {
    return (
        <m.div
            initial="hidden"
            whileInView="visible"
            // viewport={{ once: true }}
            transition={{ duration: 0.4, delay: i / 10 }}
            variants={{
                visible: { opacity: 1, y: 0 },
                hidden: { opacity: 0, y: 20 }
            }}
            className={` ${className} overflow-hidden hover:shadow-lg transition-shadow ease-in bg-white`}
        >
            <div className="px-7 py-9 xll:px-10 xll:py-20">
                <div className="w-16 h-16 relative">
                    {data.img ? (
                        <img
                            className="image"
                            src={data?.img}
                            alt="branding"
                        />
                    ) : (
                        <h1 className=" text-6xl font-bold text-accenPink">{data?.header}</h1>
                    )}
                </div>
                <h3 className="text-lg xll:text-2xl mt-8 font-bold text-gray-900">{data?.title}</h3>
                {data?.desc ? (
                    <p
                        className={`mt-4 text-base xll:text-xl ${
                            data?.btn ? " md:max-w-[280px]" : "md:max-w-xs xll:max-w-sm"
                        } text-[#4B5563]`}
                    >
                        {data?.desc}
                    </p>
                ) : (
                    <>
                        <p className="mt-4 text-base text-[#091156] md:max-w-xs">{data?.span1}</p>
                        <p className="mt-2 text-base text-[#091156] md:max-w-xs">{data?.span2}</p>
                    </>
                )}
                {data?.btn ? (
                    <button className={` ${data?.btn === "GOOGLE MAPS" ? " mt-6" : ""} btnPrimary w-full py-3`}>
                        {data?.btn}
                    </button>
                ) : (
                    ""
                )}
            </div>
        </m.div>
    )
}

export default Card
