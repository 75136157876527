import React, { useState, useRef, useEffect } from "react"
import { motion as m } from "framer-motion"

// import Lottie from "react-lottie"
import { Player, Controls } from "@lottiefiles/react-lottie-player"
import animationData1 from "../../animations/data.json"
// import animationData2 from "../../animations/animationData2.json"

// assets
import ytVideo from "../../animations/new.mp4"

const variants = {
    visible: { opacity: 1, y: 0 },
    hidden: { opacity: 0, y: 20 }
}

const AnimatingSection = () => {
    const animation1Ref = useRef()
    const animation2Ref = useRef()
    const videoRef = useRef()
    const [isAnimation1, setisAnimation1] = useState(true)
    const defaultOptions = {
        loop: true,
        autoplay: true,
        renderer: "svg"
    }

    // useEffect(() => {
    //     animation1Ref?.current?.play()
    // }, [])

    const [lineId, setLineId] = useState(1)

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.play()
        }
        const interval = setInterval(() => {
            if (lineId < 8) {
                setLineId((prev) => prev + 1)
            } else {
                setLineId(1)
            }
        }, [6300])
        return () => clearInterval(interval)
    }, [lineId])

    return (
        <section
            id="gizmo"
            className="py-10 bg-white overflow-hidden sm:py-16 lg:py-24 px-5 sm:px-8 lg:px-8 xl:px-[148px]"
        >
            <div className="mx-auto ">
                <div className=" mt-5 mx-auto text-center ">
                    <m.h2
                        initial="hidden"
                        whileInView="visible"
                        // viewport={{ once: true }}
                        transition={{ duration: 0.4 }}
                        variants={variants}
                        style={{ whiteSpace: "pre-line" }}
                        className="text-3xl xll:text-7xl font-bold sm:text-4xl sm:leading-tight"
                    >
                        {lineId === 1
                            ? `Comment start Streamline your video search
                             with Gizmo360 and beat YouTube!`
                            : lineId === 2
                            ? `Expert reviews only on Gizmo360,
                             Google is old news.`
                            : lineId === 3
                            ? `Elevate your shopping experience with Gizmo360,
                            no more relying on Amazon.`
                            : lineId === 4
                            ? `Compare and shop like a pro on Gizmo360,
                             who needs 91 Mobiles?`
                            : lineId === 5
                            ? `Stay ahead with Gizmo360's price alerts,
                             forget price history apps.`
                            : lineId === 6
                            ? `Gizmo360 is the top tech community,
                             skip Reddit and join us!`
                            : lineId === 7
                            ? `Get expert answers on Gizmo360's forums,
                             who needs Quora?`
                            : `Connect with like-minded tech enthusiasts on Gizmo360,
                             no need for Discord groups.`}
                    </m.h2>
                </div>
                <div className="mt-5 max-w-2xl text-center xll:text-2xl xll:mt-8 mx-auto">
                    <m.p
                        initial="hidden"
                        whileInView="visible"
                        // viewport={{ once: true }}
                        transition={{ duration: 0.4, delay: 0.2 }}
                        variants={variants}
                        className="text-[#4B5563] max-sm:text-sm"
                    >
                        Gizmo 360 - Your ultimate tech shopping destination! Connect with a vibrant community and
                        discover new products effortlessly. Shop confidently using our comparison options and stay
                        updated with the latest news and trends.
                    </m.p>
                </div>
                <div className=" w-[300px] md:w-[550px] lg:w-[700px] aspect-video mx-auto mt-20 relative">
                    <Player
                        ref={animation1Ref}
                        onEvent={(event) => {
                            if (event === "play") {
                                if (videoRef.current) {
                                    videoRef.current.play()
                                }
                            }
                            if (event === "loop") {
                                setisAnimation1(!isAnimation1)
                            }
                        }}
                        speed={0.68}
                        autoplay
                        loop
                        src={
                            isAnimation1
                                ? animationData1
                                : "https://gizmo360.s3.ap-south-1.amazonaws.com/animations/aniamtionData2.json"
                        }
                        style={{ height: "100%", width: "100%" }}
                    ></Player>
                    <div className=" w-[100px] xs:w-[110px] md:w-[160px] lg:w-[220px] xl:w-[200px] absolute top-[-40px]   xll:top-[220px] right-[0] lg:right-[20px] xll:right-[170px] ">
                        <video
                            ref={videoRef}
                            // width="250"
                            loop
                            // autoPlay
                            muted
                            playsInline
                        >
                            <source
                                src={ytVideo}
                                type="video/mp4"
                            />
                        </video>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AnimatingSection
