import React from "react";
import { Footer, Header } from "../components";

const Layout = ({ children }) => {
  return (
    <div>
      {window.location.pathname === "/unsubscribe" || window.location.pathname === "/success" ? (
        children
      ) : (
        <>
          <Header />
          {children}
          <Footer />
        </>
      )}
    </div>
  );
};

export default Layout;
