import React from "react"

const TestimonialCard = ({ data }) => {
    return (
        <div class="flex flex-col  overflow-hidden shadow-xl rounded-xl">
            <div class="flex flex-col justify-between flex-1 p-6 bg-white lg:py-8 lg:px-7">
                <div class="flex-1">
                    <h2 className=" text-xl font-semibold text-black text-left">{data?.title}</h2>
                    <blockquote class="flex-1 mt-4">
                        <p class="text-base font-normal text-left leading-relaxed text-[#616C87]">{data?.desc}</p>
                    </blockquote>
                </div>

                <div class="flex mt-8">
                    <img
                        class="flex-shrink-0 object-cover rounded-full w-11 h-11"
                        src={data?.img}
                        alt=""
                    />
                    <div class="ml-4 text-left">
                        <p class="text-base font-bold text-gray-900">{data?.name}</p>
                        <p class="mt-0.5  text-sm text-gray-600">{data?.role}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TestimonialCard
