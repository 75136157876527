import React, { useState } from "react"
import { motion as m } from "framer-motion"

// assets
import bannerBackground from "../../assets/images/footer/world-map.png"
import Popup from "../Popup"

const variants = {
    visible: { opacity: 1, y: 0 },
    hidden: { opacity: 0, y: 20 }
}

const Banner = () => {
    const [showModal, setShowModal] = useState(false)

    return (
        <section className="py-10 bg-accentOrange overflow-hidden relative sm:py-16 lg:py-20">
            <Popup
                showModal={showModal}
                setShowModal={setShowModal}
            />
            <div className="w-full h-[365px] md:h-[370px]  lg:h-full absolute top-0">
                <img
                    src={bannerBackground}
                    alt=""
                    className="image"
                />
            </div>
            <div className="px-4 mx-auto relative sm:px-6 lg:px-8 max-w-3xl xll:max-w-5xl">
                <div className="text-center">
                    <m.h2
                        initial="hidden"
                        whileInView="visible"
                        // viewport={{ once: true }}
                        transition={{ duration: 0.4 }}
                        variants={variants}
                        className="text-2xl font-semibold leading-tight text-white sm:text-4xl xll:leading-[5rem] lg:text-5xl xll:text-7xl"
                    >
                        Join the elite 10% of online shoppers
                    </m.h2>
                    <m.p
                        initial="hidden"
                        whileInView="visible"
                        // viewport={{ once: true }}
                        transition={{ duration: 0.4, delay: 0.2 }}
                        variants={variants}
                        className="mt-4 text-base sm:text-lg mx-auto max-w-xl text-white font-normal xll:text-xl xll:max-w-2xl"
                    >
                        And get the best value for your money. Sign up now and start saving!
                    </m.p>

                    <m.button
                        initial="hidden"
                        whileInView="visible"
                        // viewport={{ once: true }}
                        transition={{ duration: 0.4, delay: 0.3 }}
                        variants={variants}
                        className="inline-flex items-center justify-center w-full rounded-lg px-6 xll:px-8 py-3 xll:py-6 text-base xll:text-2xl mt-10 md:mt-[48px] font-semibold text-white transition-all duration-200 bg-gizmoBlack border border-transparent sm:w-auto hover:bg-white hover:text-gizmoBlack"
                        onClick={() => setShowModal(true)}
                    >
                        Sign up for Early Access
                    </m.button>
                </div>
            </div>
        </section>
    )
}

export default Banner
