import React from "react"
import { motion as m } from "framer-motion"

// local components
import Hero from "./Hero"
import Creators from "./Creators"
import Review from "./Review"
import Testimonials from "./Testimonials"
import AnimatingSection from "./AnimatingSection"
// local components
import Banner from "../../components/Footer/Banner";

// components
import { Card, ImageContent, FAQ } from "../../components"

// assets
import reviewImg from "../../assets/svg/home/review.svg"
import ratingImg from "../../assets/svg/home/rating.svg"
import forumImg from "../../assets/svg/home/forum.svg"
import shoppingImg from "../../assets/svg/home/shopping.svg"
import supportImg from "../../assets/svg/home/support.svg"
import electronicsImg from "../../assets/svg/home/electronics.svg"
import mobileContainer from "../../assets/images/home/mobileContainer.webp"

const variants = {
    visible: { opacity: 1, y: 0 },
    hidden: { opacity: 0, y: 20 }
}

const content = [
    {
        img: reviewImg,
        title: "Your Favourite Reviews",
        desc: "Discover the best products based on authentic reviews and recommendations from our community of savvy shoppers and experts alike!"
    },
    {
        img: shoppingImg,
        title: "Super Shopping",
        desc: " Discover the ultimate shopping experience with Super Shopping – your go-to destination for amazing deals and discounts on top products!"
    },
    {
        img: forumImg,
        title: "Forums",
        desc: "Join the conversation and connect with other shoppers on the latest trends, products, and more with our vibrant community forums!"
    },
    {
        img: ratingImg,
        title: "Product Ratings",
        desc: "Find the best products with ease and confidence using our comprehensive product ratings – a trusted resource for smart shoppers!"
    },
    {
        img: electronicsImg,
        title: "World of Electronics",
        desc: "Explore the latest tech and gadgets from the biggest brands in the industry with World of Electronics – your ultimate source for innovation!"
    },
    {
        img: supportImg,
        title: "GIZMO Support",
        desc: "Get the help you need, when you need it with our dedicated GIZMO Support team – always available to assist you with any questions or concerns!"
    }
]

const Home = () => {
    return (
        <div className="relative">
            <Hero />
            {/* <div className="absolute z-10 left-1/2 -translate-x-[65%] sm:-translate-x-1/2 top-[55vh] "> */}
            {/* xs:top-[calc(100vh-400px)] md:top-[calc(100vh-650px)] lg:top-[calc(100vh-300px)]
                xl:top-[calc(100vh-280px)] xll:top-[calc(100vh-400px)] */}
            {/* <img
                    className=" max-sm:max-w-[130%] xl:scale-[0.9] xll:scale-[1.6]"
                    src={mobileContainer}
                    alt="mobileContainer"
                />
            </div> */}
            <Creators />
            <Review />
            <section className="py-10 bg-creameBg sm:py-16 lg:py-24 ">
                <div className="mx-auto sectionPadding max-w-[106rem] ">
                    <div className="max-w-2xl mt-5 mx-auto text-center">
                        <m.h2
                            initial="hidden"
                            whileInView="visible"
                            // viewport={{ once: true }}
                            transition={{ duration: 0.4 }}
                            variants={variants}
                            className="text-3xl font-bold sm:text-4xl sm:leading-tight xll:text-7xl"
                        >
                            Find the perfect product with ease
                        </m.h2>
                    </div>
                    <div className="mt-5 max-w-xl text-center mx-auto">
                        <m.p
                            initial="hidden"
                            whileInView="visible"
                            // viewport={{ once: true }}
                            transition={{ duration: 0.4, delay: 0.2 }}
                            variants={variants}
                            className="text-[#4B5563] max-sm:text-sm xll:text-2xl xll:mt-8"
                        >
                            Using these features that assist you in making the best selection.
                        </m.p>
                    </div>

                    <m.div
                        transition={{ staggerChildren: 7, delayChildren: 5 }}
                        className="grid grid-cols-1 gap-6 mt-8 lg:mt-16 sm:grid-cols-2 lg:grid-cols-3 md:gap-8"
                    >
                        {content.map((card, idx) => (
                            <Card
                                i={idx}
                                key={idx}
                                data={card}
                            />
                        ))}
                    </m.div>
                </div>
            </section>
            <AnimatingSection />
            <ImageContent />
            <ImageContent section="founder" />
            <FAQ />
            <Testimonials />
            <Banner />
        </div>
    )
}

export default Home
