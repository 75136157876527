import React, { useState } from "react";

// assets
import toast, { Toaster } from "react-hot-toast";
import axios from "../../axios";

// import icons
import ReCAPTCHA from "react-google-recaptcha";
import { BsCheckCircleFill } from "react-icons/bs";

const TopModal = ({ showModal, setShowModal }) => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [form, setForm] = useState({
    name: "",
    email: "",
    // mobile_no: ""
  });
  // const [captcha, setCaptcha] = useState(false);

  const handelChange = (e) => {
    const { name, value } = e.target;
    setForm((pre) => {
      return {
        ...pre,
        [name]: value,
      };
    });
  };

  // const onChanges = (value) => {
  //   localStorage.setItem("Captcha value:", value);
  //   setCaptcha(!captcha);
  // };

  // const handleVerification = async (token) => {
  //   // Perform token verification on the server-side
  //   // Set isTokenVerified based on the verification result
  //   // For simplicity, let's assume the verification is synchronous
  //   // const isVerified = verifyTokenOnServer(token);

  //   try {
  //     const { data } = await axios.post("/google-captcha/site-verification", {
  //       token,
  //     });

  //     if (data.success) {
  //       setIsTokenVerified(data.success);
  //     } else {
  //       toast.error(data.message);
  //     }
  //   } catch (error) {
  //     toast.error("Token verification failed");
  //   }
  // };

  // const [token, setToken] = useState(false);
  const [isTokenVerified, setIsTokenVerified] = useState(false);

  // useEffect(() => {
  //   console.log("hello this is me");
  //   setToken(false);
  //   window.onloadTurnstileCallback = function () {
  //     // eslint-disable-next-line no-undef
  //     turnstile.reset();
  //     // eslint-disable-next-line no-undef
  //     turnstile.render("#myWidgetsl", {
  //       sitekey: "0x4AAAAAAAEpQccBcn_h-jOf",
  //       callback: function (token) {
  //         /**
  //          * @description if token is not undefined that means it's a valid request
  //          */
  //         if (token) {
  //           setToken(true);
  //         }
  //       },
  //     });
  //   };
  // }, [showModal]);

  const handleVerification = async (token) => {
    // Perform token verification on the server-side
    // Set isTokenVerified based on the verification result
    // For simplicity, let's assume the verification is synchronous
    // const isVerified = verifyTokenOnServer(token);

    try {
      const { data } = await axios.post("/google-captcha/site-verification", {
        token,
      });

      if (data.success) {
        setIsTokenVerified(data.success);
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error("Token verification failed");
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const add = await axios.post("/add/early-access", form);

    if (add.data.success === true) {
      toast.success(`${add.data.message}`);
      setIsLoading(false);
      setIsSuccess(true);
      setForm({
        name: "",
        email: "",
        // mobile_no: ""
      });
      return;
    } else {
      setIsLoading(false);
      return toast.error(`${add.data.message}`);
    }
  };

  return (
    <div className={`${showModal ? "block" : " hidden"} `}>
      <div
        className={`justify-center items-center flex overflow-x-hidden w-screen overflow-y-auto sectionPadding fixed inset-0 z-50 outline-none focus:outline-none`}
      >
        <div className="relative  mt-20 mx-auto max-w-3xl">
          <div
            onClick={() => setShowModal(false)}
            className=" cursor-pointer w-10 h-10 absolute bg-accentOrange grid place-items-center top-[-15px] z-10 right-[-15px] rounded-full text-white font-semibold text-xl"
          >
            X
          </div>
          {/*content*/}
          {isSuccess ? (
            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none p-4 md:p-12">
              <div className=" mx-auto relative">
                <BsCheckCircleFill size={"100px"} color="#2AAE59" />
              </div>
              <h1 className=" mt-6 text-2xl text-center font-medium">
                Sign Up successful!
              </h1>
              <p className="text-base font-normal text-black/50 max-w-[295px] text-center mt-6">
                You signed up for the Gizmo 360 app early access program, We'll
                let you know soon.
              </p>
              <button
                onClick={() => {
                  setIsSuccess(false);
                  setShowModal(false);
                }}
                className="inline-flex mt-4 items-center justify-center w-full px-6 py-3 text-base font-semibold capitalize  text-white transition-all duration-200 bg-accentOrange rounded-lg border border-transparent"
              >
                Got it
              </button>
            </div>
          ) : (
            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none p-4 md:p-12">
              <h1 className=" mt-6 text-lg md:text-2xl text-center font-medium">
                Sign Up for Early Access
              </h1>

              <form onSubmit={onSubmit} className=" mt-8">
                {/* <div>
                                    <input
                                        type="text"
                                        name="name"
                                        id="name"
                                        placeholder="Name"
                                        onChange={(e) => handelChange(e)}
                                        value={form.name}
                                        className="block w-full md:w-[350px] px-4 py-4 text-base rounded-lg text-gray-900 border border-gray-200  focus:outline-none"
                                    />
                                </div> */}
                <div>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    required
                    placeholder="Email Address"
                    onChange={(e) => handelChange(e)}
                    value={form.email}
                    className="block w-full md:w-[350px] px-4 py-4 text-base rounded-lg text-gray-900 border border-gray-200 mt-4  focus:outline-none"
                  />
                </div>
                <div className="mt-4">
                  {/* <div id="myWidgetsl"></div> */}
                  {/* <Turnstile
                    siteKey="0x4AAAAAAAEpQccBcn_h-jOf"
                    onSuccess={onChanges}
                    onChange={onChanges}
                    onReset={onChanges}
                    options={{
                      size: "normal",
                      theme: "dark",
                      execution: "render",
                      retry: "never",
                    }}
                  /> */}

                  <ReCAPTCHA
                    sitekey={process.env.REACT_APP_GOOGLE_CAPTCHA_SITE_KEY}
                    onChange={handleVerification}
                  />
                </div>
                <button
                  type="submit"
                  className={`inline-flex mt-4 items-center justify-center w-full px-6 py-3 text-base font-semibold capitalize  text-white transition-all duration-200 bg-accentOrange rounded-lg border border-transparent 
                  ${!isTokenVerified ? "cursor-not-allowed" : ""}
                  `}
                  disabled={!isTokenVerified || isLoading}
                >
                  {isLoading ? "Loading..." : "Sign up"}
                </button>
              </form>
            </div>
          )}
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
      <Toaster
        position="top-right"
        reverseOrder={false}
        className="z-[99999]"
      />
    </div>
  );
};

export default TopModal;
