import React from "react";
import { generateReactCode } from "../utils";

const content = [
  {
    title: `TERMS AND CONDITIONS`,
    paragraphs: [
      `This website [www.gizmo360.app](http://www.gizmo360.app/) (“Website”) and its mobile application “Gizmo 360” (“Mobile Application”) is authored, published and owned by Evolvum Tech Private Limited, a company (“Company”) incorporated under the Companies Act, 2013 with its registered office at No. 677, 1st Floor, Desk no: 105A, 27 Main, 13th Cross, HSR Layout, Sector 1, Bangalore, Karnataka - 560102, operating under the brand name, Gizmo 360 (“Gizmo 360”).`,
      //   `Your access and use of Gizmo360, its services and tools are governed by the following terms (“PRIVACY POLICY”), Terms and Conditions and by other applicable policies, rules, code, conditions and guidelines of Gizmo360 (collectively referred to as “Gizmo360 Policies”) which may be issued by Gizmo360 from time to time and/or which are incorporated herein by way of reference, with or without prior notification. By mere use of the Gizmo360, you are agreeing to comply with and be bound by the following Privacy Policy.``The Users (as defined in the Terms and Conditions), and any other Gizmo360 visitors are hereby collectively and severally referred to as "You" or "Users" in this Privacy Policy. The term "We", "Us", "Our", and “Company” shall mean Gizmo360 and/or its assignees, employees and authorized representatives. This privacy statement ("Privacy Policy") is a part and parcel of the Terms and Conditions and Gizmo360 Policies, which describes how we collect, use, share, disclose, and protect personal information of Users. We pledge to preserve Your privacy and any personally identifiable information or Personal Information You have provided with us on the Gizmo360 by way of our Privacy Policy. Any service that "You" uses through the Gizmo360 Platform is governed by our Terms and Conditions and our Privacy Policy. Any term used in this Privacy Policy but not defined therein shall have the meaning ascribed to it in our Terms and Conditions, as the context requires.`,
      /**
       * @note : for line 9 change " " to '' inside content
       */
      `Your access and use of Gizmo 360, its Services and tools are governed by the following terms and conditions (“**Term(s)**”) including any applicable policies, rules, code, conditions and guidelines of Gizmo 360 (**“Gizmo 360 Policies”**) which are incorporated herein by way of reference and/or which may be issued by Gizmo 360 from time to time, with or without prior notification. By mere use of the Gizmo 360, You are agreeing to comply with and be bound by the following Terms. `,
      `For the purpose of these Terms, wherever the context so requires "You" or "User(s)" shall mean any natural or legal person who visits, accesses, browses or uses Gizmo 360. The term "We", "Us", "Our", “Company” shall mean Gizmo 360.`,
      `This document is an electronic record in terms of Information Technology Act, 2000 and rules there under as applicable and the amended provisions pertaining to electronic records in various statutes as amended by the Information Technology Act, 2000. This electronic record is generated by a computer system and does not require any physical or digital signatures.`,
      `These Terms are published in compliance of, and is governed by the provisions of Indian law (as amended from time to time), including:`,
    ],
    bulletList: [
      { content: "the Indian Contract Act, 1872;" },
      {
        content:
          "The (Indian) Information Technology Act, 2000 and the rules, regulations, guidelines and clarifications framed thereunder, including the (Indian) Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Information) Rules, 2011; and",
      },
      {
        content:
          "The Information Technology (Intermediary Guidelines and Digital Media Ethics Code) Rules, 2021,",
      },
    ],
  },
  {
    space: 1,
    paragraphs: [
      `And rules and guidelines framed thereunder. It is mandatory for You to Sign Up and register Yourself with Gizmo 360 as detailed hereunder to gain access to Our Service. When You use Gizmo 360, You will be subject to the Gizmo 360 Policies applicable to any Service, and they shall be deemed to be incorporated into the Terms and shall be considered as part and parcel of the Terms. We reserve the right, at Our sole discretion, to change, modify, add or remove portions of these Terms, at any time without any prior written notice to You. The changes or any modifications will be intimated to You within thirty (30) days of its updation and it is Your responsibility to review these Terms periodically for updates / changes. Your continued use of the Gizmo 360 following the posting of changes will mean that You accept and agree to the revisions.`,
      `ACCESSING, BROWSING, DOWNLOADING OR OTHERWISE USING Gizmo 360 INDICATES Your AGREEMENT TO ALL THE TERMS AND CONDITIONS UNDER THESE TERMS, SO PLEASE READ THE TERMS CAREFULLY BEFORE PROCEEDING.`,
    ],
  },
  {
    sub_title: `1. Representation and Warranties:`,
    paragraphs: [
      `By accessing or using Gizmo 360, You represent and warrant that:`,
    ],
  },
  {
    sub_title: ``,
    paragraphs: [
      `A. You are either at least 18 years old or You are using Gizmo 360 under parental/legal guardian’s guidance and consent, at all times; `,
      `B. Use of Gizmo 360 is available only to persons who can enter into legally binding contracts under the Indian Contract Act, 1872. Persons who are incompetent to contract within the meaning of the Indian Contract Act, 1872 including minors, un-discharged insolvents and persons of unsound mind are not eligible to use Gizmo 360.`,
      `C. Gizmo 360 reserves the right to terminate any person’s membership and/or refuse to provide such a person with access to the Website/Mobile Application if it is brought to Gizmo 360’s notice or if it is discovered that such person is not eligible to use the Website/Mobile Application.`,
      `D. You have the lawful authority and capacity to contract and be bound by these Terms;`,
      `E. If You are accepting these Terms on behalf of a company, limited liability partnership or other legal entity, You have the authority to bind such entity to these Terms and, in such event, “You” and “Your” as used in these Terms shall refer to such entity;`,
      `F. You agree to adhere to the laws, rules, Regulations, Circulars, Notifications, Guidelines, By-laws and Policies issued by authorities that are/may be applicable to You including but not limited to the laws of India, for the time being in force (“**Laws**”),  at all times;`,
      `G. You agree that Gizmo 360 is a social media platform that allows You to learn and share about the latest technology, electronic items, devices and gadgets provided by various brands (**“Products”**), post/share photos, videos, polls, statuses, reviews, feedbacks, comments, recommendations etc., about the Products or Services or on any such matters, as required and permitted for posting/sharing by Gizmo 360 (**“Content”**), like, share and comment on such Content posted by other Users, and connect and communicate with other Users, by accepting followers and following other Users (“**Connections**”) on the platform (“**Service(s)**”).`,
    ],
  },
  {
    sub_title: `2. Access and Use`,
    paragraphs: [
      `Gizmo 360 grants You a limited, personal, non-exclusive, non-transferable and non-sublicensable right and access to use Gizmo 360, subject to the Terms and the following representations and warranties:`,
      `A. You shall only access Gizmo 360 using authorized and lawful means;`,
      `B. Gizmo 360 shall not be liable for any failure or default to provide access to the Gizmo 360 on account of any failure or delay by You to register with the Gizmo 360 as the case may be for such access;`,
      `C. Any configuration or set up of the Devices for access to the Gizmo 360 shall be Your sole responsibility. “Device” means a device, usually electronic, that processes data according to a set of instructions, including but not limited to workstations, personal computers, laptops, netbooks, personal digital assistants, tablets, and smartphones;`,
      `D. We collect, store, process and use Your information in accordance with Company’s Privacy Policy. By using Gizmo 360 and/or by providing Your Personal Information (as defined in the Privacy Policy), You consent to the collection and use of the information You disclose to Us, in accordance with the Privacy Policy;`,
      `E. Gizmo 360 can only be accessed by signing up using Your mobile number and by providing all the other details necessitated by Gizmo 360. (**“Sign Up”**) After Signing Up with Gizmo 360 and registering the necessitated details, You can log in and access Your profile/account (**“Account”**) using the login credentials.`,
      `F. You are solely responsible to keep Your login credentials and accessibility to Your Account, including but not limited to Your e-mail id, Your passwords, access to Your Devices etc., secure; You shall immediately notify Gizmo 360 of any actual or suspected or unauthorised use or access of Your Account by any other User or third parties. Although Gizmo 360 shall not be liable for any of the consequential losses caused by any unauthorised use of Your Account, You may be held liable for the damage or loss caused to Gizmo 360, its Users or any third parties as the case may be, due to any unauthorised use of Your Account.`,
      `G. You shall upload Your true and accurate Profile information to Your Account, to enable other Users to interact with You on Gizmo 360 and for Gizmo 360 to provide effective Services to its Users. However, in respect of modification or change or update of information that You wish to make in Your Account, Gizmo 360 reserves its rights to verify, refuse or allow such modification or change or updation upon verification or authentication. `,
      `H. Gizmo 360 shall not be liable for any fake, inactive, innaccurate, fraudulent, misleading, impersonating, non-existing Accounts created by Users. Upon identification of such Account(s) or upon suspicion of any malpractices, Gizmo 360 reserves the right to delete/deactivate such and other related Accounts, without prior notice to the Users.`,
      `I. You shall not knowingly include or use any false, inaccurate, incomplete, disintegrated or prohibited information while using Gizmo 360; You shall not take any action that interferes with, degrades or adversely affects the Services and reputation of Gizmo 360;`,
      `J. You shall not use Gizmo 360 in a manner (i) that is prohibited by any law or regulation, or facilitates the violation of any law or regulation; or (ii) that shall disrupt the usage of Gizmo 360 by any other Users (iii) that violates or tampers or reasonably appears to violate or tamper with the programmed functioning and security of Gizmo 360;`,
      `K. You shall not use Gizmo 360, for other purposes including but not limited to transmit, publish, post, upload, distribute or disseminate any inappropriate, harassing, abusive, defamatory, libellous, obscene, illegal or deceptive Content as opposed to the applicable Laws;`,
      `L. You shall not copy, modify, adapt, translate, store, transmit, publish, distribute, disseminate, or reverse engineer the information or the features available in Gizmo 360, without specific written authorization from Gizmo 360, that otherwise violates and infringes the rights of Gizmo 360 in any manner whatsoever, in accordance with the applicable Laws;`,
      `M. You shall ensure that Gizmo 360 is not used to upload, post, transmit, or otherwise make available any Content that contains a virus or any other form of malicious code or data that is likely or intended to have an adverse impact on Gizmo 360 or any other software, hardware, Services or data in and of Gizmo 360 or provide unauthorized access of Gizmo 360, to any other Users or any third parties;`,
      `N. You shall not attempt to gain unauthorised access to any accounts, computer systems or networks connected to Gizmo 360, through hacking, or any other means, or obtain or attempt to obtain any materials or information through any means not intentionally made available to You;`,
      `O. You shall not copy, modify, adapt, translate, or reverse engineer any portion of the Gizmo 360, its features, materials and/or the Services. You shall not access or use Gizmo 360 to develop, or to assist anyone in developing competitive and/or similar Services provided by Gizmo 360;`,
      `P. You are prohibited from endorsing, publishing, soliciting, promoting, sharing, distributing, or transmitting any photos, videos, statuses, polls, comments, reviews, hyperlinks, invitations, offers, in any form, whether directly or indirectly, to the Users for any other purpose, inside or outside Gizmo 360, including but not limited to: `,
    ],
    bulletList: [
      {
        content:
          "Any unsolicited communications for the purpose of collecting or disseminating information from the Users etc.",
      },
      {
        content: "Information relating to providing job opportunities;",
      },
      {
        content:
          "Hosting/promoting/sharing information about or hyperlinks to any third party applications, softwares or events in Gizmo 360;",
      },
      {
        content:
          "Hosting/promoting/sharing information about any product or service provided by You or any third parties or Users;",
      },
      {
        content:
          "sharing of information that relate to sexual Contents, prostitution, consumption and use of alcohol or narcotic drugs or psychotropic substances, child /women trafficking and any such prohibited activities; or",
      },
      {
        content:
          "sharing of information that is prohibited and is punishable under the Indian Penal Code, 1860 or any other law for the time being in force; or",
      },
      {
        content:
          "Promotion of political party(ies), their agendas, their ideologies; or",
      },
      {
        content:
          "Promotion of any religion(s), religious sentiments, practices and activities ",
      },
      {
        content:
          "Sharing Harmful Code (as defined under Clause 9(f) herein) to access, collect, copy, decipher, use, alter, transform, duplicate, modify, process, decompile, disassemble, frame, hotlink, deep link or reverse engineer any information of the User or Gizmo 360, irrespective of the nature of the information and the purpose, affecting the rights or interests or security or privacy or reputation of the Users or of Gizmo 360, whether directly or indirectly;",
      },
      {
        content:
          "Sharing information that is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic, pedophilic, libelous, invasive of another's privacy, hateful, or racially, ethnically objectionable, disparaging, relating or encouraging money laundering or gambling, or otherwise unlawful in any manner whatever;",
      },
      {
        content:
          "Sharing of information that threatens the unity, integrity, defence, security or sovereignty of India, friendly relations with foreign states, or public order or causes incitement to the commission of any cognizable offence or prevents investigation of any offence or is insulting any other nation;",
      },
      {
        content:
          "And sharing any other information, documents, materials, Contents that are unlawful, illegal, harmful, misleading, misrepresentative, infringing the rights including intellectual property rights and against the interests of other Users or Gizmo 360.",
      },
    ],
  },
  {
    sub_title: ``,
    paragraphs: [
      `Q. You shall be solely responsible for (i) procuring or maintaining Your Connections and telecommunications links from Your systems to the Company’s data centres and (ii) all problems, conditions, delays, delivery failures and all other loss or damage arising from or relating to Your network Connections or telecommunications links or caused by the internet;`,
      `R. You acknowledge that from time to time, Gizmo 360 may introduce, insert, modify, alter, delete, post, or update any data, information, services, algorithm, features, facilities and/or any other information in Gizmo 360, whether for the purpose of fixing an error, bug or other issues in the Gizmo 360 or enhancing the functionality of Gizmo 360 (“Upgrades”) and that such Upgrades may result in changes in the features, appearance and/or functionality of Gizmo 360. You may be required to install some of the Upgrades to Gizmo 360 in Your Device(s) in order to continue to access or use Gizmo 360, or portions thereof.`,
      `S. Gizmo 360 has absolute rights over the User Contents and information You share or post or publish or disseminate or distribute in Gizmo 360 though it does not and cannot review every such Content or information made on the Gizmo 360. These Terms do not require Gizmo 360 to monitor, police or remove any postings or other information submitted by any User and Gizmo 360 shall not be responsible for any ensuing liability. However, You acknowledge that Gizmo 360 may exercise its editorial/censorship rights over any posts, Content or information shared by You, if the same are not consistent with these Terms and/or Privacy Policy and/or any other Gizmo 360 Policies. `,
      `T. You acknowledge that Gizmo 360 or any of its associates or affiliates may directly or indirectly use any Content posted by You for educational, training, marketing, promotional, and other commercial purposes. You further acknowledge that Gizmo 360 or any of its associates or affiliates may promote/market/commercialise Your Content in or outside Gizmo 360, along with the details of Your Account, without any prior notice.`,
      `U. You acknowledge that Gizmo 360 reserves the right to report any Content, including but not limited to posts, comments or other communications that imply harm or imminent danger to yourself, other Users, Gizmo 360 or the general public at large. By submitting such Content, You accept that the Gizmo 360 has the right to contact the appropriate authorities to report any suspicious activities, threats or behaviors.`,
      `V. You are prohibited from using any engine, software, tool, agent or any device or mechanism (such as spiders, robots, avatars or intelligent agents) to navigate or search Gizmo 360;`,
      `W. You agree that Gizmo 360 may contact You through telephone, email, SMS, or any other electronic means of communication for the purpose of:`,
    ],
    bulletList: [
      {
        content:
          "Obtaining feedback in relation to Website/Mobile Application or Gizmo 360’s Services; and/or",
      },
      {
        content:
          "Obtaining feedback in relation to any Users listed or to be listed on the Gizmo 360; and/or",
      },
      {
        content:
          "Resolving any complaints, information, or queries by other users etc.",
      },
      {
        content:
          "And for any other purpose in order to provide efficient and effective Services to the Users;",
      },
    ],
  },
  {
    sub_title: ``,
    paragraphs: [
      `And You agree to provide Your fullest cooperation to further such communication by Gizmo 360, subject to these Terms.`,
      `X. You acknowledge that Gizmo 360 shall not be liable directly or indirectly at any time, for any Content shared by any of the Users at Gizmo 360 and such Users shall be solely responsible for the Content shared by them.`,
      `Y. Gizmo 360 has discretionary rights to publish or modify or moderate or mask (where required by law or norm etc.) the Contents or feedback or comments or ratings provided by the Users, as necessitated under circumstances.`,
      `Z. All communications transmitted through Gizmo 360, including without limitation to Our messaging services and calls, shall be monitored and stored by Gizmo 360 for quality assurance, training and other purposes. By accepting these Terms, You consent to any such monitoring and storage.`,
      `AA. Gizmo 360 follows strict confidentiality policy and You shall not directly or indirectly make use of or divulge, any information that is not in the public domain and intellectual property rights whether registered or not, relating to Gizmo 360 or its Users, personnel, associates, affiliates or its clients (including but not limited to logos, trademarks, know-how, patents, copyrights, trade secrets, design rights, rights relating to computer software, data, inventions, discoveries, source codes, designs, software programs, applications, database, flowcharts, depictions, summaries, materials, documentation, records, forms, compilations, executables, binaries, discussion notes, health records, messages, chats, medical prescriptions, diagnosis reports, therapy notes and any other industrial or intellectual property rights) to any person, without specific authorization from Gizmo 360 at all times.`,
      `BB. You acknowledge that the Terms, Privacy Policy and Gizmo 360 Policies, with or without notice, in addition to or in modification of these Terms shall bind You under all circumstances.`,
      `CC. By accepting these Terms, You unequivocally agree not to act against the interests and reputation of Gizmo 360, in any manner whatsoever.`,
    ],
  },
  {
    sub_title: `3. Mode of Operation:`,
    paragraphs: [
      `A. Gizmo 360 can be accessed by You, on Signing Up. Once You have Signed Up and registered Your Account, You shall create Your profile by clicking on the prompt available on the top of Your Screen, containing the Profile Menu (**“Profile”**). For creating Your Profile, You shall be required to enter Your personal details like Your name, contact details, etc.`,
      `B. After setting up Your Profile, You shall be required to provide details regarding Your gender, date of birth, etc. for personalising Your feed in Gizmo 360. You may be allowed to use Gizmo 360, only upon completion of all the necessitated process of setting up Your Profile.`,
      `C. Gizmo 360 provides its Services free of cost to the Users, however, Gizmo 360 reserves the absolute right to alter or modify the provision of Services based on a subscription fee payable by the Users monthly/annually or in such mode and manner as may be applicable and as provided by Gizmo 360 (**“Subscription”**). `,
      `D. Other than the above-mentioned features, Gizmo 360 facilitates a panoramic and user-friendly experience by providing further options for the effective usage of its Services: `,
    ],
    bulletList: [
      {
        content:
          "The ‘All’ option enables the Users to view various forms of Content based on Your interests, likes, comments, location  and other activity.",
      },
      {
        content:
          "The “Search” options enables Users to search for Content or Users on the search bar by entering names, related key words, hashtags, etc., and refine the search results by applying the available filters.",
      },
      {
        content:
          "The “Report” option enables Users to report any activity or Content by other Users, in the event that it is sexual, violent, hateful, abusive, harmful, dangerous, spam, spreads misinformation, and such reports shall be acted upon by Gizmo 360 through appropriate measures after a thorough review of the same, based upon the legitimacy of the report. After the receipt of such a report, and upon the sole discretion and opinion of Gizmo 360, any such activity or Content shall be removed by Gizmo 360. ",
      },
      {
        content:
          "You may join “Forums” concerned with a specific arena of interest, share Content and view relevant Content in relation the said arena on such Forums.",
      },
    ],
  },
  {
    sub_title: ``,
    paragraphs: [
      `E. You accept and acknowledge that You may receive advertisements and promotional posts based on Your usage, activity and interests on Gizmo 360.`,
      `F. You accept and acknowledge that You may receive notifications from Gizmo 360 in relation to Your referrals, account, channel, recommendations, responses to posts and comments, community post suggestions, and such other features. However, You may disable the receipt of such notifications by disallowing the same in the settings.`,
      `G. Gizmo 360 enables its Users to edit their Profile information to a permissible extent, track the Users’ activity in Gizmo 360.`,
      `H. Gizmo 360 showcases a wide variety of Content based on Your Profile and preferences. The Services are showcased by Gizmo 360 based on a) Your location, b) Your preferences and c) preferences of other Users from the same location and/or with the same preferences as Yours etc.`,
      `I. You accept and acknowledge that the Content displayed to You on Gizmo 360 shall be based on, including but not limited to Users that You opt to follow, Forums that You may join, Content that You or Your Connections create/like/respond to/share, Your location, and information that We have about others and their activity to show You more relevant Content or advertisements. You may choose the “Not interested” prompt in relation to any Content shared by other Users in order to hinder Gizmo 360 from displaying any Content that You do not wish to view or such Content of similar nature.`,
      `J. You accept and acknowledge that, Gizmo 360 may, track Your activity on other applications and utilise this information to provide You with Content and personalized advertisements that may be of relevance to you, without any prior notice.`,
      `K. You accept and acknowledge that, Gizmo 360 records and monitors all Your activity on the Website/Mobile application.`,
      `L. You may opt to unfollow any User at any time.`,
      `M. You may opt to enable the “Two Factor Athentication” under the Settings on the Website/Mobile Application. The information provided by You for enabling the “Two Factor Athentication” shall be protected and governed under the Privacy Policy of Gizmo 360.`,
      `N. You accept and acknowledge that the Content shared by You may be utilised by Us for the purposes of marketing and promotion of various Products displayed on Gizmo 360.`,
      `O. You acknowledge and undertake that You are accessing the Services of Gizmo 360 and transacting at Your own risk and are using Your best and prudent judgment before entering into any transactions through Gizmo 360. You further acknowledge and undertake that You shall use Gizmo 360 only for Your personal use and not for any commercial or business purposes.`,
      `P. We further expressly disclaim any warranties or representations (express or implied) in respect of quality, suitability, accuracy, reliability, completeness, timeliness, performance, safety, merchantability, fitness for a particular purpose, or legality of the Services listed or displayed or transacted or the Content on the Gizmo 360. While We have taken precautions to avoid inaccuracies in the Content on Gizmo 360, all Content, information, software, Products, services and related graphics are provided as is, without warranty of any kind. We do not implicitly or explicitly support or endorse the sale or purchase of any Products on the Website/Mobile Application. At no time shall any right, title or interest in the Services sold through or displayed on Gizmo 360 vest with Gizmo 360 nor shall Gizmo 360 have any obligations or liabilities in respect of any transactions on the Website/Mobile Application.`,
    ],
  },
  {
    sub_title: `4. Agreement to receive Mails & Phone calls`,
    paragraphs: [
      `By way of accepting these Terms You hereby consent to the receipt of communication from Us  by way of emails, newsletters, WhatsApp messages & Phone calls and agree to provide necessitated information and feedback required by Gizmo 360.`,
    ],
  },
  {
    sub_title: `5. Geographic Limitation: `,
    paragraphs: [
      `A. You may access the Services or other features on the Gizmo 360 only in geographic locations where Gizmo 360 offers the same on account of the applicable Laws. Gizmo 360 reserves the right to use technologies, programming or data to verify Your geographic location, at any point in time after Your Signing Up.`,
    ],
  },
  {
    sub_title: `6. Links to other Sites:`,
    paragraphs: [
      `A. Gizmo 360 may contain links to other websites or software or mobile applications controlled by third parties. Gizmo 360 is not responsible for and does not endorse the Contents or use of these websites or software or mobile applications.`,
    ],
  },
  {
    sub_title: `7. Public posts, Content and Rating:`,
    paragraphs: [
      `A.  As a participant in Gizmo 360, You agree to use careful, prudent, and good judgment when posting/sharing Contents at Gizmo 360. `,
      `B. When You post/share a Content (including but not limited to feedbacks, ratings etc.), You represent and warrant that (a) You are the sole author and owner of the intellectual property and any other rights in that Content or have the right to use that Content with appropriate consents and permissions) (b) give Us permission to post or otherwise use Your Content for marketing and promotional purposes (c) Your Content does not violate these Terms or Gizmo 360 Policies or applicable Laws;`,
      `C. We reserve the right to remove any Content, if it (a) contain libellous or otherwise unlawful, abusive or obscene material; (b) attack the reputation of Gizmo 360; (c) are unrelated to the post or content to which you have reviewed or commented on (d) violates these Terms or Gizmo 360 Policies or applicable Laws (e) is inappropriate or violates propriety or privacy of another user, Gizmo 360, and for such other reasons that Gizmo 360 may identify in the context of any Content.`,
      `D. Gizmo 360, in its sole discretion, may delete Your Content; and/or report to law enforcement authorities any actions that may be illegal, and any reports it receives of such conduct. When legally required or at Our discretion, We will cooperate with law enforcement agencies in any investigation of alleged illegal activity on Gizmo 360. `,
      `E. We do not and cannot review issues regarding every Content posted/shared on Gizmo 360. These Terms do not require Us to monitor, police or remove any postings or other information submitted by You or any other user and We shall not be responsible for any ensuing liability.`,
    ],
  },
  {
    sub_title: `8. Your Reviews`,
    paragraphs: [
      `A.  Gizmo 360 may allow You to post certain Content, data or information of Your own, such as reviewing or allowing You to share Your experience and views about a particular Services, post Your comments and reviews in relation to Contents on the Website/Mobile Application on specific pages of the Website/Mobile Application, as well as submit any suggestions, comments, questions or other information to Gizmo 360.`,
      `B. You, being the originator of the Content, are responsible for the Content that You upload, post, publish, transmit or otherwise make available on the Website/Mobile Application. You represent that You have obtained all relevant consents and approvals in order to post any Content. You further represent that all such Content will be in accordance with applicable law. You acknowledge that Gizmo 360 does not endorse any Content on the Website/Mobile Application and is not responsible or liable for any Content uploaded, posted, transmitted or published by You. Gizmo 360 reserves the right to disable the viewership or access to any Content at its sole discretion.`,
      `C. You hereby grant Gizmo 360 a perpetual, non-revocable, worldwide, royalty-free and sub-licensable right and license to use, copy, distribute, display, publish, transmit, make available, reproduce, modify, adapt the Content uploaded, posted, transmitted or published and create derivative works of the such Content. You represent and warrant that You own or otherwise control all of the rights to the Content that You post or otherwise provide on or through the Website/Mobile Application; and that, as on the date that the Content is posted or submitted on Gizmo 360: (i) the Content is accurate; (ii) use of the  Content You supply does not breach these Terms; and (iii) that such Content is lawful.`,
      `D. You further represent and warrant that while posting any Content on the Website/Mobile Application You shall not use any offensive, libelous, derogatory, hateful or racially or ethnically objectionable language. Further, You shall not post any Content on the Website/Mobile Application that is obscene, pornographic, constitutes an “indecent representation of women” as provided in The Indecent Representation of Women (Prohibition) Act, 1986 and adhere to these Terms at all times.`,
    ],
  },
  {
    sub_title: `9. Intellectual Property Rights:`,
    paragraphs: [
      `A) You hereby acknowledge that any and all intellectual property rights (including but not limited to all trademark, copyright, patent, service marks, domain name etc.) and other proprietary rights in and in relation to Gizmo 360 and the Service provided therein, including without limitation any compilation, data, materials, articles, drawings, depictions, explanations, call records, derivatives, improvements or modifications which ownership is directly attributable to Gizmo 360 (expressly excluding any personal information, intellectual property which belongs to a third party) and any suggestions, ideas, enhancement requests, feedback, recommendations or other information provided by You, other Users or third parties relating to Gizmo 360 or the Services (**“Intellectual Property Rights”**) shall vest wholly, completely, and fully with Gizmo 360 throughout the territory of the world and You shall not directly or indirectly infringe Intellectual Property Rights and have no right or claim to the Intellectual Property Rights in any manner whatsoever.`,
      `B) Gizmo 360 respects the intellectual property rights of others and expects You to do the same. At Gizmo 360’s discretion and in appropriate circumstances, Gizmo 360 may remove Your Content submitted to the Website/Mobile Application and/or terminate the accounts of users or prevent access to the Website/Mobile Application by users who infringe the intellectual property rights of others. If You believe the intellectual property rights in Your work or in the work for which You act as an agent has been infringed through Gizmo 360, You may report such Content through the “Report” action and submit a Copyright Infringement Notice. Upon submission of such a notice, Gizmo 360 shall thoroughly review the report, provide a response within two weeks and take appropriate measures in relation to the same within 30 days of Your submission, based upon the legitimacy of the report.`,
    ],
  },
  {
    sub_title: `10. Disclaimer of Warranties and Liabilities:`,
    paragraphs: [
      `A.  You understand and agree that Gizmo 360 functions on an 'as-is,' 'with all faults,' and 'as available' basis. You agree that the use of the Gizmo 360 is at Your risk. All warranties including without limitation, the implied warranties of merchantability and fitness for a particular purpose for title and non-infringement are disclaimed and excluded.`,
      `B. No representations, warranties or guarantees are made by Us regarding the (1) accuracy, adequacy, reliability, completeness, suitability or applicability of the information to a particular situation; (2) that the features will be uninterrupted, timely, secure, or error-free; (3) the quality of any Services, Content, information, or other material on the Gizmo 360 will meet Your expectations or requirements; (4) any errors in the Gizmo 360 will be corrected; (5) warranties against infringement of any third party intellectual property or proprietary rights; or (6) other warranties relating to performance, non-performance, or other acts or omissions of the Company, its officers, directors, employees, affiliates, agents, licensors, or suppliers.`,
      `C. Gizmo 360 does not make any representation or warranty regarding the reliability, accuracy, completeness, correctness, or usefulness of third party Content, and disclaims all liabilities arising from or related to third party Content.`,
      `D. The Company does not warrant that any of the software used and or licensed in connection with the Gizmo 360 will be compatible with other third party software or devices nor does it warrant that operation of the Gizmo 360 and the associated software will not damage or disrupt other software or hardware.`,
      `E. Notwithstanding anything contained elsewhere in these Terms, in no event shall the Company be liable to You or anyone claiming under You for any costs or loss incurred or suffered by You or anyone claiming under You, including but not limited to any special, exemplary, consequential, incidental, punitive or indirect damages on any theory of liability, whether in contract, tort (including without limitation negligence), strict liability or otherwise. In no event or circumstance will the Company be under any liability to make good any loss whether by way of any monetary payment or otherwise.`,
      `F. The Company or anyone else involved in administering, distributing or providing the Services of Gizmo 360 further explicitly disclaims any and all liability for any mistakes, omissions, interruptions, deletion of files or email, errors, defects, viruses, computer viruses or other harmful, disabling computer code, computer instructions, circuitry or other technological means whose purpose is to disrupt, damage or interfere with any computer and communications facilities or equipment ("**Harmful Code**") that may be transferred to Your Devices when accessing the Gizmo 360. By way of clarification, Harmful Code shall include, without limitation, any code containing viruses, Trojan horses, spiderbots, worms or similar destructive code or code that was intentionally written to self-replicate. You are advised to obtain and use appropriate anti-virus and security software and to take all other appropriate measures to safeguard the integrity of Your Devices.`,
    ],
  },
  {
    sub_title: ` 11. Indemnification:`,
    paragraphs: [
      `A) Notwithstanding anything provided under these Terms or Privacy Policy, by accepting these Terms and using the Gizmo 360, You agree that You shall defend, indemnify and hold the Company, its directors, shareholders, officers, technicians, other service providers and other representatives harmless from and against any and all claims, costs, damages, losses, liabilities and expenses (including attorneys' fees and costs) arising out of or in connection with: (1) Your access to Gizmo 360, (2) Your use of the Services/features/facilities of Gizmo 360, or (3) Your violation or breach of these Terms or any applicable law or regulation; (4) Your violation of any Intellectual Property Rights or that of any other User or third party; or (5) Your misrepresentation, negligence or fraudulent activities in connection with the usage of Gizmo 360 (6) any and all third-party claims based upon the Content or communications transmitted by You.`,
    ],
  },
  {
    sub_title: `12. Termination:`,
    paragraphs: [
      `These Terms will continue to apply until terminated by either You or Us  as set forth below:`,
      `A. **Termination by You:** If You wish not to be bound by these Terms, You may terminate Your relationship with Us  by deactivating/deleting Your Account and stop accessing and using Gizmo 360.  Please note that deactivating Your account does not delete any or all the Contents from Your Account.  You will need to delete them personally if You decide to do so. You may deactivate Your Account on Gizmo 360 at anytime. You acknowledge that upon deactivating Your Account, Gizmo 360 offers You an option to reactivate and restore Your Account within 30 days of such deactivation. Please note that deactivating Your Account does not delete any or all the Contents from Your Account as the said information belong to Gizmo 360. Gizmo 360 owns the right to store, retain, modify, alter, use, transmit, publish, disseminate, distribute, remove or delete the said Contents in the manner and purpose, as decided by Gizmo 360.`,
      `B. **Termination by Us:**  Gizmo 360 may at its discretion and at any time with or without notice, with or without examination/enquiry, terminate Your Account or suspend the Terms, restrain or limit the usage of certain features or Services, with or without cause if: (i) Gizmo 360 is unable to verify or authenticate any information provided by You or (ii) You breach any of the provisions of these Terms, the Privacy Policy or Terms or any other Laws, anywhere in the world, that may be applicable to You; or (iii) the Company is required to do so in accordance with or in pursuance of Your violation of, applicable Laws; or (iv) Gizmo 360 has reasonable grounds for suspecting any illegal, fraudulent or abusive activity on part of You or on account of any of Your activity suspected to be against the interests of any User or Gizmo 360 (iii) Company has elected to discontinue, with or without reason, access to Gizmo 360 (or any part thereof) either in general or specifically to You.`,
      `C. Gizmo 360 shall not be liable to You or any third party for any such termination.`,
    ],
  },
  {
    sub_title: `13.  General:`,
    paragraphs: [
      `A. To the extent that anything in or associated with Gizmo 360 is in conflict or inconsistent with these Terms, these Terms shall take precedence and prevail. Our failure to enforce any provision of these Terms shall not be deemed a waiver of such provision nor of the right to enforce such provision. Our rights under these Terms shall survive any discontinuance of the access or use of Gizmo 360.`,
    ],
  },
  {
    sub_title: `14. GENERAL:`,
    paragraphs: [
      `A. Gizmo360 strongly encourages parents and guardians to supervise the online activities of their minor children and consider using parental control tools available from online services and software manufacturers to help provide a child-friendly online environment. These tools also can prevent minors from disclosing their name, address, and other personally identifiable information online without parental permission. Although Gizmo360  and its services are not intended for use by minors, Gizmo360 respects the privacy of minors who may inadvertently use the internet or the mobile application.`,
      `B. If any provision of these Terms is held to be invalid or unenforceable, such provision shall be struck and the remaining provisions shall be enforced to the fullest extent under law.`,
      `C. Any notices to You from Us  regarding Gizmo 360 or these Terms will be notified to You on Gizmo 360 or sent to You through Your registered e-mail.`,
      `D. **Governing Law**: These Terms shall be governed and construed under the Laws of India, without any reference to its conflict of law rules and the Courts of Bengaluru, Karnataka, India shall have exclusive jurisdiction. In case of any disputes or differences between the Users and Gizmo 360, the same shall be resolved by Conciliation under Arbitration and Conciliation Act, 1996. If the said disputes or differences are not settled by Conciliation, the Parties shall resolve them by Arbitration before the sole arbitrator under Arbitration and Conciliation Act, 1996. The language of the arbitration proceedings shall be English and the seat shall be at Bengaluru, Karnataka, India.`,
    ],
  },
  {
    sub_title: `15. Severability:`,
    paragraphs: [
      `A. All the portions of these Terms, are intended to be and shall be, independent and severable, and in the event that any term or portion thereof is determined to be unlawful, invalid or unenforceable, such determination shall not affect the validity or enforceability of any other provision, or portion thereof, of these Terms, and all other provisions and portions thereof shall continue to be valid and enforceable.`,
    ],
  },
  {
    sub_title: `16. Grievance Redressal:`,
    paragraphs: [
      `A. We encourage You to contact Us, the Gizmo 360 Nodal Officer vide the below indicated contact details (as provided in accordance with Information Technology Act, 2000 and any Rules made thereunder) if You have any questions concerning Our Terms or the Gizmo 360 or any other queries or grievances or clarifications in relation to the Terms or access/usage of Gizmo 360.`,
    ],
  },
  {
    sub_title: ``,
    paragraphs: [
      `Grievance/Nodal Officer: Mr./Ms. Anbuchelian PE`,
      `M/s. Evolvum Tech Private Limited,`,
      `**Address:**  No. 677, 1st Floor, Desk no: 105A, 27 Main, 13th Cross, HSR Layout, Sector 1, Bangalore, Karnataka - 560102.`,
      `**Email ID:** info@gizmo360.app`,
      `Lodge Your complaints at (portal address): [www.Gizmo360.app](https://www.Gizmo360.app/)`,
    ],
  },
];

const elements = generateReactCode(content);

const TermsConditions = () => {
  return (
    <div className="max-w-7xl mx-auto break-words px-8">
      {elements}
      <div className="mb-24"></div>
    </div>
  );
};

export default TermsConditions;
