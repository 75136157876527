import React, { useState } from "react";

// assets
import toast, { Toaster } from "react-hot-toast";
import appleImg from "../../assets/images/footer/apple-store.svg";
import googleImg from "../../assets/images/footer/google-play.svg";
import logo from "../../assets/logo/logo-sm.svg";
import axios from "../../axios";

const Footer = () => {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handelSubmit = async () => {
    if (email.length === 0) {
      return toast.error("Please fill the email");
    }
    setIsLoading(true);
    const add = await axios.post("/add/news-letter", { email });

    if (add.data.success) {
      toast.success(`${add.data.message}`);
      setEmail("");
      setIsLoading(false);
      return;
    } else {
      setIsLoading(false);
      toast.error(`${add.data.message}`);
    }
  };
  return (
    <>
      <footer className="py-12 bg-gray-50 sm:pt-16 lg:pt-20 xll:p-40">
        <div className=" sectionPadding mx-auto  max-w-[106rem]">
          <div className="flex flex-wrap items-start md:justify-between gap-y-12 gap-x-24 md:gap-x-12">
            <div className="w-full lg:w-64 xl:w-full xl:max-w-xs">
              <a href="/" title="" className="flex">
                <img className="w-auto h-12" src={logo} alt="logo" />
              </a>

              <p className="mt-8 text-base font-normal text-gray-500">
                Gizmo 360 - bringing you the best of tech, all in one place!
                Shop smarter, connect better, and explore the world of
                innovation today!
              </p>
              <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-2 gap-4 mt-6">
                <img className="w-full" src={appleImg} alt="" />
                <img className="w-full" src={googleImg} alt="" />
              </div>
            </div>

            <div>
              <p className="text-base font-bold tracking-widest text-[#61748E] uppercase">
                Links
              </p>

              <ul className="mt-8 space-y-5">
                <li>
                  <a
                    href="#home"
                    className="inline-flex text-base font-medium text-gray-900 transition-all duration-200 hover:translate-x-1 hover:text-accentOrange"
                  >
                    {" "}
                    Home{" "}
                  </a>
                </li>

                <li>
                  <a
                    href="#feature"
                    title=""
                    className="inline-flex text-base font-medium text-gray-900 transition-all duration-200 hover:translate-x-1 hover:text-accentOrange"
                  >
                    {" "}
                    Features{" "}
                  </a>
                </li>

                <li>
                  <a
                    href="#gizmo"
                    title=""
                    className="inline-flex text-base font-medium text-gray-900 transition-all duration-200 hover:translate-x-1 hover:text-accentOrange"
                  >
                    {" "}
                    Why GIZMO360{" "}
                  </a>
                </li>

                <li>
                  <a
                    href="#faq"
                    title=""
                    className="inline-flex text-base font-medium text-gray-900 transition-all duration-200 hover:translate-x-1 hover:text-accentOrange"
                  >
                    {" "}
                    FAQ{" "}
                  </a>
                </li>
              </ul>
            </div>

            <div>
              <p className="text-base font-bold tracking-widest text-[#61748E] uppercase">
                Others
              </p>

              <ul className="mt-8 space-y-5">
                <li>
                  <a
                    href="#testimonial"
                    title=""
                    className="inline-flex text-base font-medium text-gray-900 transition-all duration-200 hover:translate-x-1 hover:text-accentOrange"
                  >
                    {" "}
                    Testimonials{" "}
                  </a>
                </li>

                <li>
                  <a
                    href="mailto:info@gizmo360.in"
                    target="_blank"
                    title=""
                    className="inline-flex text-base font-medium text-gray-900 transition-all duration-200 hover:translate-x-1 hover:text-accentOrange"
                    rel="noreferrer"
                  >
                    {" "}
                    Help & Support{" "}
                  </a>
                </li>
                <li>
                  <a
                    href="/terms-conditions"
                    title=""
                    className="inline-flex text-base font-medium text-gray-900 transition-all duration-200 hover:translate-x-1 hover:text-accentOrange"
                  >
                    {" "}
                    Terms & Conditions{" "}
                  </a>
                </li>
                <li>
                  <a
                    href="/privacy-policy"
                    title=""
                    className="inline-flex text-base font-medium text-gray-900 transition-all duration-200 hover:translate-x-1 hover:text-accentOrange"
                  >
                    {" "}
                    Privacy Policy{" "}
                  </a>
                </li>
              </ul>
            </div>

            <div className="w-full md:max-w-xs">
              <p className="text-base font-bold tracking-widest text-[#61748E] uppercase">
                Newsletter
              </p>

              <div className="mt-5 space-y-4">
                <label htmlFor="email" className="sr-only">
                  {" "}
                  Email address{" "}
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  placeholder="Enter your email address"
                  className="block w-full px-4 py-4 text-base rounded-lg text-gray-900 border border-gray-200   focus:outline-none"
                />
              </div>

              <button
                disabled={isLoading}
                onClick={handelSubmit}
                type="submit"
                className="inline-flex mt-2 items-center justify-center w-full px-6 py-3 text-base font-semibold capitalize  text-white transition-all duration-200 bg-accentOrange rounded-lg border border-transparent"
              >
                {isLoading ? "Loading..." : "Subscribe Now"}
              </button>
            </div>
          </div>

          <div className="pt-12 mt-12 text-center border-t border-gray-200 sm:mt-16 lg:mt-20">
            <p className="text-sm font-normal text-gray-900">
              © Copyright {new Date().getFullYear()}, All Rights Reserved by
              Evolvum Tech Private Limited
            </p>
          </div>
        </div>
        <Toaster
          position="top-right"
          reverseOrder={false}
          className="z-[99999]"
        />
      </footer>
    </>
  );
};

export default Footer;
