import React, { useState } from "react"
import { motion as m } from "framer-motion"

// third party
import { Accordion, AccordionHeader, AccordionBody } from "@material-tailwind/react"
import { ChevronDown } from "react-feather"

const variants = {
    visible: { opacity: 1, y: 0 },
    hidden: { opacity: 0, y: 20 }
}

const FAQ = () => {
    const [open, setOpen] = useState(1)

    const handleOpen = (value) => {
        setOpen(open === value ? 0 : value)
    }

    // custom styles for animation
    const customAnimation = {
        mount: { scale: 1 },
        unmount: { scale: 0.9 }
    }

    return (
        <section
            id="faq"
            className="py-10 bg-creameBg sm:py-16 lg:py-24 xll:py-60"
        >
            <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
                <div className=" mx-auto text-center">
                    <m.h2
                        initial="hidden"
                        whileInView="visible"
                        // viewport={{ once: true }}
                        transition={{ duration: 0.4 }}
                        variants={variants}
                        className="text-3xl font-bold xll:text-7xl leading-tight text-black sm:text-4xl lg:text-5xl"
                    >
                        Frequently Asked Questions
                    </m.h2>
                </div>

                <div className="max-w-3xl xll:scale-[1.5] xll:mt-32 mx-auto mt-8 space-y-4 md:mt-16">
                    <m.div
                        initial="hidden"
                        whileInView="visible"
                        // viewport={{ once: true }}
                        transition={{ duration: 0.4 }}
                        variants={variants}
                        className="relative inline-flex mt-10 group"
                    >
                        {/* <div className="absolute transitiona-all duration-1000 opacity-20 -inset-px bg-gradient-to-r from-[#44beff27] via-[#ff44ec1f] to-[#ff665e21] rounded-xl blur-lg group-hover:opacity-80 group-hover:-inset-1 group-hover:duration-200 animate-tilt"></div> */}
                        <div className="transition-all duration-200 bg-white border border-gray-200 cursor-pointer hover:bg-gray-50 rounded-lg">
                            <Accordion
                                open={open === 1}
                                icon={<ChevronDown id={1} />}
                                className="relative transition-all bg-white duration-200  rounded-lg"
                                animate={customAnimation}
                            >
                                <AccordionHeader
                                    className="flex px-5 text-left text-base sm:text-lg font-semibold text-black"
                                    onClick={() => handleOpen(1)}
                                >
                                    What is Gizmo 360?{" "}
                                </AccordionHeader>
                                <AccordionBody className="px-4 pb-5 sm:px-6 sm:pb-6 font-semibold">
                                    Gizmo 360 is a social shopping app that helps you discover and buy consumer tech
                                    products with ease and confidence. It offers features such as expert product
                                    reviews, ratings, forums, and personalized recommendations.
                                </AccordionBody>
                            </Accordion>
                        </div>
                    </m.div>
                    <m.div
                        initial="hidden"
                        whileInView="visible"
                        // viewport={{ once: true }}
                        transition={{ duration: 0.4 }}
                        variants={variants}
                        className="relative inline-flex mt-10 group"
                    >
                        <div className="transition-all duration-200 bg-white border border-gray-200 cursor-pointer hover:bg-gray-50 rounded-lg">
                            <Accordion
                                open={open === 2}
                                icon={<ChevronDown id={1} />}
                                className="relative transition-all bg-white duration-200  rounded-lg"
                                animate={customAnimation}
                            >
                                <AccordionHeader
                                    className="flex px-5 text-left text-base sm:text-lg font-semibold text-black"
                                    onClick={() => handleOpen(2)}
                                >
                                    Is Gizmo 360 free to use?
                                </AccordionHeader>
                                <AccordionBody className="px-4 pb-5 sm:px-6 sm:pb-6 font-semibold">
                                    Yes, Gizmo 360 is completely free to download and use. However, some features may
                                    require you to create an account or sign up for a subscription.
                                </AccordionBody>
                            </Accordion>
                        </div>
                    </m.div>
                    <m.div
                        initial="hidden"
                        whileInView="visible"
                        // viewport={{ once: true }}
                        transition={{ duration: 0.4 }}
                        variants={variants}
                        className="relative inline-flex mt-10 group"
                    >
                        <div className="transition-all duration-200 bg-white border border-gray-200 cursor-pointer hover:bg-gray-50 rounded-lg">
                            <Accordion
                                open={open === 3}
                                icon={<ChevronDown id={1} />}
                                className="relative transition-all bg-white duration-200  rounded-lg "
                                animate={customAnimation}
                            >
                                <AccordionHeader
                                    className="flex px-5 text-left text-base sm:text-lg font-semibold text-black"
                                    onClick={() => handleOpen(3)}
                                >
                                    How can I download Gizmo 360?{" "}
                                </AccordionHeader>
                                <AccordionBody className="px-4 pb-5 sm:px-6 sm:pb-6 font-plus_jakarta_sans">
                                    <p className="font-plus_jakarta_sans font-semibold">
                                        You can download Gizmo 360 from the App Store or Google Play Store, depending on
                                        your device's operating system.
                                    </p>
                                </AccordionBody>
                            </Accordion>
                        </div>
                    </m.div>
                    <m.div
                        initial="hidden"
                        whileInView="visible"
                        // viewport={{ once: true }}
                        transition={{ duration: 0.4 }}
                        variants={variants}
                        className="relative inline-flex lg:w-[770px] mt-10 group"
                    >
                        <div className="transition-all w-full duration-200 bg-white border border-gray-200 cursor-pointer hover:bg-gray-50 rounded-lg">
                            <Accordion
                                open={open === 6}
                                icon={<ChevronDown id={1} />}
                                className="relative transition-all bg-white duration-200  rounded-lg"
                                animate={customAnimation}
                            >
                                <AccordionHeader
                                    className="flex px-5 text-left text-base sm:text-lg font-semibold text-black"
                                    onClick={() => handleOpen(6)}
                                >
                                    How does Gizmo 360 personalize my shopping experience?
                                </AccordionHeader>
                                <AccordionBody className="px-4 pb-5 sm:px-6 sm:pb-6 font-semibold">
                                    Gizmo 360 uses advanced algorithms and machine learning to analyze your preferences,
                                    behavior, and purchase history and suggest products that match your interests and
                                    needs.
                                </AccordionBody>
                            </Accordion>
                        </div>
                    </m.div>
                    <m.div
                        initial="hidden"
                        whileInView="visible"
                        // viewport={{ once: true }}
                        transition={{ duration: 0.4 }}
                        variants={variants}
                        className="relative inline-flex lg:w-[770px] mt-10 group"
                    >
                        <div className="transition-all w-full duration-200 bg-white border border-gray-200 cursor-pointer hover:bg-gray-50 rounded-lg">
                            <Accordion
                                open={open === 4}
                                icon={<ChevronDown id={1} />}
                                className="relative transition-all bg-white duration-200  rounded-lg"
                                animate={customAnimation}
                            >
                                <AccordionHeader
                                    className="flex px-5 text-left text-base sm:text-lg font-semibold text-black"
                                    onClick={() => handleOpen(4)}
                                >
                                    Does Gizmo 360 offer customer support?{" "}
                                </AccordionHeader>
                                <AccordionBody className="px-4 pb-5 sm:px-6 sm:pb-6 font-semibold">
                                    Yes, Gizmo 360 has a dedicated support team that can assist you with any issues or
                                    questions you may have. You can contact them through the app or via email.{" "}
                                </AccordionBody>
                            </Accordion>
                        </div>
                    </m.div>
                    <m.div
                        initial="hidden"
                        whileInView="visible"
                        // viewport={{ once: true }}
                        transition={{ duration: 0.4 }}
                        variants={variants}
                        className="relative inline-flex lg:w-[770px] mt-10 group"
                    >
                        <div className="transition-all w-full duration-200 bg-white border border-gray-200 cursor-pointer hover:bg-gray-50 rounded-lg">
                            <Accordion
                                open={open === 5}
                                icon={<ChevronDown id={1} />}
                                className="relative transition-all bg-white duration-200  rounded-lg"
                                animate={customAnimation}
                            >
                                <AccordionHeader
                                    className="flex px-5 text-left text-base sm:text-lg font-semibold text-black"
                                    onClick={() => handleOpen(5)}
                                >
                                    Can I trust the product reviews and ratings on Gizmo 360?
                                </AccordionHeader>
                                <AccordionBody className="px-4 pb-5 sm:px-6 sm:pb-6 font-semibold">
                                    Yes, Gizmo 360 prides itself on providing authentic and unbiased product reviews and
                                    ratings from experts and real users. However, it's always a good idea to do your own
                                    research and make informed decisions.
                                </AccordionBody>
                            </Accordion>
                        </div>
                    </m.div>

                    <m.div
                        initial="hidden"
                        whileInView="visible"
                        // viewport={{ once: true }}
                        transition={{ duration: 0.4 }}
                        variants={variants}
                        className="relative inline-flex lg:w-[770px] mt-10 group"
                    >
                        <div className="transition-all w-full duration-200 bg-white border border-gray-200 cursor-pointer hover:bg-gray-50 rounded-lg">
                            <Accordion
                                open={open === 7}
                                icon={<ChevronDown id={1} />}
                                className="relative transition-all bg-white duration-200  rounded-lg"
                                animate={customAnimation}
                            >
                                <AccordionHeader
                                    className="flex px-5 text-left text-base sm:text-lg font-semibold text-black"
                                    onClick={() => handleOpen(7)}
                                >
                                    Can I connect with other users and tech enthusiasts on Gizmo 360?
                                </AccordionHeader>
                                <AccordionBody className="px-4 pb-5 sm:px-6 sm:pb-6 font-semibold">
                                    Yes, Gizmo 360 has a vibrant community of tech enthusiasts who share their opinions,
                                    experiences, and recommendations on the app's forums and social media channels. You
                                    can also follow other users and interact with them through comments and messages.
                                </AccordionBody>
                            </Accordion>
                        </div>
                    </m.div>
                </div>
            </div>
        </section>
    )
}

export default FAQ
