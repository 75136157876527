import React from "react"
import { useLocation, useRoutes } from "react-router-dom"
import { animateScroll as scroll } from "react-scroll"

// ** GetRoutes
import routes from "./routes/pages"

const Router = () => {
  const route = useRoutes(routes)

  const { hash } = useLocation()

  React.useEffect(() => {
    scroll.scrollToTop({
      smooth: true,
      duration: 500
    })

    if (hash) {
      const sectionId = hash.slice(1) // Remove the "#" symbol from the hash
      const element = document.getElementById(sectionId)

      if (element) {
        // get element height and subtract 20% of it
        const elementHeight = element.offsetHeight - element.offsetHeight * 0.2

        scroll.scrollTo(element.offsetTop + elementHeight, {
          smooth: true,
          duration: 500
        })
      }
    }
  }, [hash])

  return route
}

export default Router
