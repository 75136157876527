import React from "react"
import { motion as m } from "framer-motion"

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react"
// import required modules
import { Pagination } from "swiper"

// Import Swiper styles
import "swiper/css"
import "swiper/css/pagination"

// components
import { TestimonialCard } from "./../../components"

// assets
import userImg1 from "../../assets/images/home/user1.webp"
import userImg2 from "../../assets/images/home/user2.webp"
import userImg3 from "../../assets/images/home/user3.webp"
import userImg4 from "../../assets/images/home/user4.webp"

const variants = {
    visible: { opacity: 1, y: 0 },
    hidden: { opacity: 0, y: 20 }
}

const testimonialData = [
    {
        title: "What a wonderful platform!!!",
        desc: "As a tech enthusiast, Gizmo 360 has been a game-changer for me. I can stay up-to-date on the latest trends, products while connecting with like-minded individuals. Great platform!!",
        name: "Nabil Samad",
        role: "Tech Enthusiast",
        img: userImg1
    },
    {
        title: "Shopping to !!!",
        desc: "I love how user-friendly Gizmo 360 is. It's so easy to browse products and read reviews, and the community aspect adds an extra layer of engagement. Definitely one of the best shopping apps out there!. ",
        name: "Aadhya M",
        role: "Online Shopper",
        img: userImg2
    },
    {
        title: "Product ratings and expert insights!!!",
        desc: "Thanks to Gizmo 360, I was able to find the perfect laptop for my needs without feeling overwhelmed by the endless options out there. The product ratings and expert insights were invaluable. ",
        name: "Vishnu Prasad",
        role: "Online Shopper",
        img: userImg3
    },
    {
        title: "The best platform to shopping!!!",
        desc: "Gizmo 360 has saved me so much time and hassle when shopping for electronics. The expert opinions and user reviews have helped me make informed decisions and avoid buyer's remorse. ",
        name: "Nithya Shree",
        role: "Business Owner",
        img: userImg4
    }
]

const Testimonials = () => {
    return (
        <section
            id="testimonial"
            className="py-10 bg-white sm:py-16 lg:py-24 px-5 sm:px-8 lg:px-8 xl:px-[148px] xll:py-60"
        >
            <div className="mx-auto text-center">
                <m.h5
                    initial="hidden"
                    whileInView="visible"
                    // viewport={{ once: true }}
                    transition={{ duration: 0.4 }}
                    variants={variants}
                    className=" text-base xll:text-2xl font-semibold text-[#616C87]"
                >
                    Testimonial
                </m.h5>
                <m.div
                    initial="hidden"
                    whileInView="visible"
                    // viewport={{ once: true }}
                    transition={{ duration: 0.4, delay: 0.2 }}
                    variants={variants}
                    className="max-w-2xl xll:max-w-4xl mt-3 mx-auto text-center"
                >
                    <h2 className="text-3xl font-bold sm:text-4xl sm:leading-tight xll:text-7xl">
                        What Our Beta Users Say
                    </h2>
                </m.div>
                <m.div
                    initial="hidden"
                    whileInView="visible"
                    // viewport={{ once: true }}
                    transition={{ duration: 0.4, delay: 0.3 }}
                    variants={variants}
                    className="mt-5 max-w-md xll:max-w-2xl text-center mx-auto"
                >
                    <p className="text-[#616C87] xll:text-2xl xll:mt-8 text-base max-sm:text-sm">
                        Discover what our beta users have to say about their experience with Gizmo 360 Social Shopping
                        and join the community today!
                    </p>
                </m.div>

                <div className="w-full max-w-[1200px] mt-20 xll:mt-40 mx-auto xll:scale-[1.2]">
                    <Swiper
                        // slidesPerView={1}
                        spaceBetween={40}
                        // centeredSlides={true}
                        breakpoints={{
                            320: { slidesPerView: 1 },
                            480: { slidesPerView: 1 },
                            768: { slidesPerView: 2 },
                            1024: { slidesPerView: 2 }
                        }}
                        pagination={{
                            clickable: true
                        }}
                        modules={[Pagination]}
                        className="mySwiper md:!px-7"
                    >
                        {testimonialData.map((data, idx) => (
                            <SwiperSlide key={idx}>
                                <TestimonialCard data={data} />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </div>
        </section>
    )
}

export default Testimonials
