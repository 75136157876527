import React from "react";
import logo from "../assets/logo/logo-sm.svg";
import { useState } from "react";
import axios from "../axios";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useJwt } from "react-jwt";
import { useEffect } from "react";

const UnsubscribePage = () => {
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState("");
  const [load, setLoad] = useState(false);
  const [info, setInfo] = useState({
    email: "",
    reason: "",
  });
  const [searchParams] = useSearchParams();
  const Token = searchParams.get("token");
  const { decodedToken, isExpired } = useJwt(Token);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handelChange = (name, value) => {
    setInfo((pre) => {
      return {
        ...pre,
        [name]: value,
      };
    });
  };

  useEffect(() => {
    const APi = async () => {
      handelChange("email", decodedToken.id);
    };
    APi();
  }, [decodedToken]);

  const handelSubmit = async () => {
    if (Token === null) {
      setLoad(false)
      toast.error("something went wrong");
      return
    }

    if (info.email.length === 0 || selectedOption.length === 0) {
      return toast.error("Please fill all the details");
    }
    const { data } = await axios.post(
      "/unSubscribe",
      {
        email: info.email,
        type: selectedOption,
        reason: info.reason,
      },
      {
        headers: {
          Authorization: Token,
        },
      }
    );

    if (data.success) {
      setInfo({
        reason: "",
      });
      navigate("/success");
      return;
    } else {
      setLoad(false)
      toast.error(`${data.message}`);
      return 
    }
  };

  return (
    <div className="my-3 flex items-center justify-center">
      <div className="overflow-hidden flex items-center flex-col w-[1000px] h-[800px]  rounded-lg py-8">
        <img className="w-auto h-10 lg:h-12 my-6" src={logo} alt="logo" />

        <h1>Please enter your email address and unsubscribe choice.</h1>

        <div className="flex flex-col my-6">
          <label className=" text-base font-semibold my-1">Email</label>
          <input
            name="email"
            value={info.email}
            disabled
            type="email"
            placeholder="Enter your email-Id"
            className="border-2 border-[#404040] border-solid w-[600px] px-2 py-2 outline-none rounded-sm"
          />
        </div>

        <div className="flex flex-col gap-y-3 -ml-[350px]">
          <h1 className="font-bold text-2xl my-2">Unsubscribe Method</h1>
          <label className="flex items-center text-xl">
            <input
              type="radio"
              value="Early Access"
              checked={selectedOption === "Early Access"}
              onChange={handleOptionChange}
              className="mx-4 w-6 h-6"
            />
            Early Access
          </label>

          <label className=" text-xl flex items-center">
            <input
              type="radio"
              value="News Letter"
              checked={selectedOption === "News Letter"}
              onChange={handleOptionChange}
              className="mx-4 w-6 h-6"
            />
            News Letter
          </label>
        </div>

        <div className="flex flex-col my-6">
          <label className=" text-base font-semibold my-1">
            Reason (optional)
          </label>
          <textarea
            name="reason"
            value={info.reason}
            onChange={(e) => handelChange("reason", e.target.value)}
            className="w-[600px] h-[200px] border-2 border-[#404040] border-solid px-2 py-2 outline-none rounded-sm"
          />
        </div>
        <button
          onClick={() => {
            setLoad(true);
            handelSubmit();
          }}
          className=" bg-orange-400 px-8 py-2 rounded-lg text-white font-semibold text-lg"
        >
          {load ? "Loading..." : "Unsubscribe"}
        </button>
      </div>
      <Toaster
        position="top-center"
        reverseOrder={false}
        className="z-[99999]"
      />
    </div>
  );
};

export default UnsubscribePage;
