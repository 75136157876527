import React from "react";
import { generateReactCode } from "../utils";

const content = [
  {
    title: `DATA PROTECTION & PRIVACY POLICY`,
    paragraphs: [
      `This website [www.gizmo360.app](http://www.gizmo360.app/) and its **mobile** **application** “Gizmo360” is authored, published and owned by Evolvum Tech Private Limited, a company incorporated under the Companies Act, 2013 with its registered office at No. 677, 1st Floor, Desk no: 105A, 27 Main, 13th Cross, HSR Layout, Sector 1,  operating under the brand name, Gizmo360 (“**Gizmo360**”).`,
      //   `Your access and use of Gizmo360, its services and tools are governed by the following terms (“PRIVACY POLICY”), Terms and Conditions and by other applicable policies, rules, code, conditions and guidelines of Gizmo360 (collectively referred to as “Gizmo360 Policies”) which may be issued by Gizmo360 from time to time and/or which are incorporated herein by way of reference, with or without prior notification. By mere use of the Gizmo360, you are agreeing to comply with and be bound by the following Privacy Policy.``The Users (as defined in the Terms and Conditions), and any other Gizmo360 visitors are hereby collectively and severally referred to as "You" or "Users" in this Privacy Policy. The term "We", "Us", "Our", and “Company” shall mean Gizmo360 and/or its assignees, employees and authorized representatives. This privacy statement ("Privacy Policy") is a part and parcel of the Terms and Conditions and Gizmo360 Policies, which describes how we collect, use, share, disclose, and protect personal information of Users. We pledge to preserve Your privacy and any personally identifiable information or Personal Information You have provided with us on the Gizmo360 by way of our Privacy Policy. Any service that "You" uses through the Gizmo360 Platform is governed by our Terms and Conditions and our Privacy Policy. Any term used in this Privacy Policy but not defined therein shall have the meaning ascribed to it in our Terms and Conditions, as the context requires.`,
      /**
       * @note : for line 9 change " " to '' inside content
       */
      `We, Evolvum Tech Private Limited, are committed to providing the best user experience, ensuring User(s)’ data protection and privacy. The objective of this Privacy Policy is to inform User(s) about the data collection and their usage by Gizmo360.`,
      `This Privacy Policy is published and shall be construed in accordance with the provisions of:`,
    ],
    lists: [
      {
        content: [
          `The Information Technology Act, 2000`,
          `Rule 3 (1) of the Information Technology (Intermediary Guidelines and Digital Media Ethics Code) Rules, 2021 (**“Rules, 2021”**) under Information Technology Act, 2000 that requires publishing the Rules and Regulations, Privacy Policy and Terms and Conditions for access or usage of Gizmo360.`,
          `Rule 4 of the Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Information) Rules, 2011 under Information Technology Act, 2000 (hereinafter referred to as the **“SPI Rules”**)`,
        ],
      },
    ],
  },
  {
    space: 1,
    paragraphs: [
      `By using the services or by otherwise giving us Your information, You will be deemed to have read, understood and agreed to the practices and policies outlined in this Privacy Policy and agree to be bound by the Privacy Policy. You hereby provide Your consent for collection, use, sharing, disclosure of Your information as described in this Privacy Policy. We reserve the right to change, modify, add or delete portions of the Terms of this Privacy Policy, at our sole Discretion, at any given time. If You do not Agree with this Privacy Policy, You are at liberty to opt out of the services provided by “Us” at any given time. If You use the services on behalf of someone else or an Entity, It would be assumed that  “You” are authorised by such individual or Entity to;`,
    ],
    lists: [
      {
        content: [
          `Accept this Privacy Policy on such individual’s or Entity’s behalf, and;`,
          `Consent on behalf of such individual or Entity to our collection, use, sharing, and disclosure of such individual’s or Entity’s information as described in this Privacy Policy.`,
        ],
      },
    ],
  },
  {
    sub_title: `This Privacy Policy states the following:`,
    lists: [
      {
        content: [
          `The type of information collected from the Users, including Personal Information and Sensitive Personal Data or Information (as defined under Clause 1 herein) relating to an individual;`,
          `The purpose, means and modes of collection, usage, processing, retention and destruction of such information;`,
          `How and to whom Gizmo360 will disclose such information.`,
        ],
      },
    ],
  },
  {
    sub_title: `I. COLLECTION OF PERSONAL INFORMATION:`,
    paragraphs: [
      `A)  \t It is mandatory for You to register yourself with Gizmo360 as detailed hereunder to gain access to their contents. Gizmo360 may collect personal as well as non-personal information from its Users. Such non-personal information may include but is not limited to, the details of Your geographical location, Your internet service provider, the type of Your internet browser, the operating system of Your device, the website or Application that You last visited before visiting Gizmo360, the website or Application which You may visit next to Gizmo360 and/or the duration of Your stay on Gizmo360.`,
      `B)  \t The information collected from you by Gizmo360 may constitute “personal information” or “sensitive personal data or information” under the “SPI Rules.” “Personal Information” is defined under Section 2, clause (i) of the SPI Rules to mean any information that relates to a natural person, which, either directly or indirectly, in combination with other information available or likely to be available to a body corporate, is capable of identifying such person. Section 3 of the SPI Rules provides that “Sensitive Personal Data or Information” of a person means such personal information which consists of information relating to:`,
    ],
    bulletList: [
      { content: "passwords;" },
      {
        content:
          "financial information such as bank accounts, credit and debit card details or other payment instrument details;",
      },
      { content: "physical, physiological and mental health conditions;" },
      { content: "sexual orientation;" },
      { content: "medical records and history;" },
      { content: "biometric information;" },
      {
        content:
          "any detail relating to the above clauses as provided to body corporate for",
      },
      { content: "providing service;" },
      {
        content:
          "any of the information received under the above clauses by the body corporate for processing, stored or processed under lawful contract or otherwise;",
      },
    ],
  },
  {
    sub_title: ``,
    paragraphs: [
      `C) When You sign up and register with Gizmo360, in addition to the above non-personal information, Gizmo360 may also collect Your personal information such as Your User Name, Password, Name, Gender, Age, Date of Birth, Shipping Address, Address, Telephone Number, Mobile Number, E-mail, photos and other files on Your device, contacts saved on Your device, data collected from other applications installed on Your device, Your financial information such as bank account information or credit card or debit card or other payment instrument details, Internet Protocol (IP) Address, Your preferences and usage behaviour on Gizmo360, any feedback or review or messages or comments, irrespective of them being, text, photos or videos, that You may post on Gizmo360 and/or any other information in the nature of sensitive personal data.`,
      `D) A User may have limited access to the Gizmo360 without creating an Account on the Gizmo360. In order to have access to all the features and benefits on our Gizmo360, a User must first create an Account in the Gizmo360. To create an Account, a User is required to provide the following information, which such User recognises and expressly acknowledges as Personal Information, which may include the User’s name, User ID, email address, age, phone number, location, password chosen by the User, valid financial account information, etc., as required by Gizmo360. Other information requested while Signing Up, including the ability to receive promotional offers from Gizmo360, is optional. Gizmo360 may, in future, include other optional requests for information from the User to help Gizmo360 to customise Gizmo360 to deliver personalised information to the User.`,
      `E) Gizmo360 occasionally conducts User surveys to collect information about Users' preferences. These surveys are optional and if Users choose to respond, their responses will be kept anonymous. The demographic information that Gizmo360 collects in the registration process while Signing Up and through surveys is used to help Gizmo360 improve its services to meet the needs and preferences of Users.`,
      `F) No Sensitive Personal Data or Information is automatically collected by Gizmo360 from any casual visitors of Gizmo360. Nevertheless, certain provisions and obligations under this Privacy Policy and Terms and Conditions are applicable to even such casual visitors, and such casual visitors are also required to read and understand the privacy statements set out herein, failing which they are required to depart from Gizmo360 immediately.`,
      `G) If You, as a casual visitor, have inadvertently browsed any other page of Gizmo360 prior to reading the privacy statements set out herein, and You do not agree with the manner in which such information is obtained, collected, processed, stored, used, disclosed or retained, merely quitting this browser application should ordinarily clear all temporary cookies installed by Gizmo360. All visitors, however, are encouraged to use the “clear cookies” functionality of their browsers to ensure such clearing/ deletion, as Gizmo360 cannot guarantee, predict or provide for the behavior of the equipment of all the visitors of the Gizmo360. You are not a casual visitor and if You have willingly submitted any Personal Information to Gizmo360 through any means, including email, post or through the registration process on the Gizmo360, all such visitors will be deemed to be, and will be treated as, Users for the purposes of this Privacy Policy, and in which case, all the statements in this Privacy Policy apply to such persons.`,
      `H) You acknowledge that the Gizmo360 will be free to use, collect and disclose any User information that is freely available in the public domain without Your consent. Gizmo360 does not collect information about the casual visitors of the Gizmo360 from other sources, such as public records or bodies, or private organizations, save and except for the purposes of registration of the Users and to effectively render its services in Gizmo360).`,
    ],
  },
  {
    sub_title: `II. USAGE OF INFORMATION:`,
    paragraphs: [
      `A)  \t The condition for each User’s use of and access to the Gizmo360 is their acceptance of the Terms and Conditions and any other Gizmo360 Policies as the case may be, which also involves acceptance of the terms of this Privacy Policy. Any User that does not agree with any provisions of the same may opt to discontinue the services provided by Gizmo360 immediately.`,
      `B)  \t The primary purpose of Gizmo360 collecting information from You is to provide You a secure and hassle-free experience that is customized to suit Your interests and requirements. You understand, agree and acknowledge that collection, storage and processing of Your information is lawful and is required for the functioning of Gizmo360 and to provide You its services.`,
      `C) \t All the information provided to Gizmo360 by a User, including Personal Information or any Sensitive Personal Data or Information, is voluntary. You understand that Gizmo360 may use certain information of Yours, which has been designated as Personal Information or ‘Sensitive Personal Data or Information’ under the SPI Rules:`,
    ],
    bulletList: [
      { content: "For the purpose of providing services to You," },
      {
        content:
          "To help You address Your problems or grievances incurred in relation to Your transactions with Gizmo360;",
      },
      {
        content:
          "To send You notices regarding any changes in the Terms and Conditions, Gizmo360 policies, guidelines and/or other administrative information;",
      },
      {
        content:
          "To conduct internal reviews, data analysis, analytical studies, research and surveys on user behavior, user preferences, usage of Gizmo360;",
      },
      {
        content:
          "commercial purposes and in an aggregated or non-personally identifiable form for research, statistical analysis and business intelligence purposes; and for sale or transfer of such research, statistical or intelligence data in an aggregated or non-personally identifiable form to third parties and affiliates;",
      },
      {
        content:
          "To take action against any person who may have violated or is being likely to violate or is causing another to violate any provision of law including the Information Technology Act, 2000 that is likely to have an adverse impact on Gizmo360 or services provided by Gizmo360;",
      },
      { content: "To improve or develop new features on Gizmo360;" },
      {
        content:
          "For communication purposes to obtain feedback or ratings or postings;",
      },
      { content: "For debugging customer support related issues." },
    ],
  },
  {
    sub_title: ``,
    paragraphs: [
      `D) If You have voluntarily provided Your Personal Information to Gizmo360 for any of the purposes stated above, You hereby consent to such collection and use of such information by Gizmo360.`,
      `E) Gizmo360 shall not contact You on Your telephone number(s) for any purpose, if such telephone number is registered with the National Do Not Call Registry (“DNC Registry”) maintained by the Telecom Regulatory Authority of India, without Your express, clear and un-ambiguous written consent.`,
      `F) Gizmo360 may also disclose or transfer Users’ Personal Information, Sensitive Personal Data or Information and any other information provided by a User, to a third party as part of reorganisation or a sale of the assets of a Gizmo360 corporation division or company. Any third party to which Gizmo360 transfers or sells its assets to will have the right to continue to use the Personal Information, Sensitive Personal Data or Information and any other information that Users provide to us, in accordance with the Terms and Conditions.`,
      `G) If any other information is to be collected/released/stored/disposed/modified from the Users other than that which has already been stated in this Privacy Policy, a separate permission would be taken from the Users in the form of Pop-ups/disclaimers/permissions for e.g., audio/video permission, access to photos/videos stored on your device, location permission, notification service, app background permission, etc., and such other permissions as may be necessary. There might be other permissions/information which Gizmo360 would require to provide a smooth experience to its mobile application Users, and Gizmo360 reserves the right to ask any such information from the User as it deems fit to serve its objective as an intermediary.`,
    ],
  },
  {
    sub_title: `III. SHARING OF INFORMATION:`,
    paragraphs: [
      `A)  \t Gizmo360 may share the information collected from You, whether personal or non- personal, to,`,
    ],
    bulletList: [
      {
        content:
          "Gizmo360’s business affiliates or partners to facilitate Your use of Gizmo360 or to effectively complete and fulfill Your transaction with Gizmo360;",
      },
      {
        content:
          "Third parties who may need to contact You for facilitating customer service and customer support;",
      },
      {
        content:
          "Third parties for facilitating marketing and advertising of Gizmo360 services;",
      },
      {
        content:
          "Another business entity in the event of merger, acquisition, amalgamation, reorganization or restructuring of Gizmo360’s business (or assets) with or by such other business entity;",
      },
      {
        content:
          "Respond to legal, judicial, quasi judicial process, law enforcement agencies or in connection with an investigation on matters related to public safety, as permitted by law.",
      },
    ],
  },
  {
    sub_title: ``,
    paragraphs: [
      `B) Whenever Gizmo360 shares Your information with third parties, Gizmo360 will do so under a confidentiality agreement prohibiting such third parties from sharing Your personal information unlawfully or unauthorized for purposes other than for which Your information is being shared to them.`,
      `C) Gizmo360 may provide links to third-party sites that may or may not collect personal information from You. Gizmo360 is not responsible for the collection of information by such third-party sites or for the privacy policies of such third-party sites. Once You are redirected from Gizmo360, You are no longer governed by this Privacy Policy or the Terms and Conditions provided by Gizmo360. You are highly encouraged to carefully read the privacy policies of such third party sites before using them.`,
    ],
  },
  {
    sub_title: ``,
    paragraphs: [
      `D)  \t  Gizmo360 reserves the right to use information provided by you for the following purposes:`,
    ],
    bulletList: [
      {
        content: "Publishing such information on Gizmo360.",
      },
      {
        content:
          "Contacting Users for feedback/ratings of the products and services.",
      },
      {
        content:
          "Analyzing software usage patterns for improving product design and utility.",
      },
      {
        content: "Analyzing anonymised information for commercial use and",
      },
      {
        content:
          "for such other purposes so to provide effective and efficient services to the Users",
      },
    ],
  },
  {
    sub_title: ``,
    paragraphs: [
      `E) Whenever Gizmo360 shares Your information with third parties, Gizmo360 will do so under a confidentiality agreement prohibiting such third parties from sharing Your personal information unlawfully or unauthorized for purposes other than for which Your information is being shared to them`,
      `F) **Usage of data in anonymised form**: Due to the communications’ standards on the Internet, a User visiting Gizmo360 automatically receives the URL of the site from which anyone visits. Gizmo360 also receives the Internet Protocol (IP) address of each User’s computer (or the proxy server a User used to access the World Wide Web), User’s computer operating system and type of web browser the User is using, email patterns, as well as the name of User’s ISP. This information is used to analyze overall trends to help Gizmo360 improve its service. The linkage between User’s IP address and User’s personally identifiable information/Personal Information is not shared with or disclosed to third parties. Notwithstanding the above, Gizmo360 may share and/or disclose some of the aggregate findings (not the specific data) in anonymised form (i.e., non-personally identifiable) with advertisers, sponsors, investors, strategic partners, and others in order to help grow its business.`,
      `G) **Disclosure of Personal Information**: To the extent necessary to provide Users with the services, Gizmo360 may provide their Personal Information to third party contractors who work on behalf of or with Gizmo360 to provide Users with such services, to help Gizmo360 communicate with Users or to maintain the Gizmo360. Generally these contractors do not have any independent right to share this information, however certain contractors who provide services on the Gizmo360, including the providers of online communications services, may use and disclose the Personal Information collected in connection with the provision of these services in accordance with their own privacy policies. In such circumstances, You consent to us disclosing Your Personal Information to contractors, solely for the intended purposes only.`,
    ],
  },
  {
    sub_title: `IV.  RESPONSIBILITY FOR MAINTAINING ACCURACY OF THE INFORMATION:`,
    paragraphs: [
      `A) **You are responsible for maintaining the accuracy of the information:** Gizmo360 displays the information provided by the Users on an as-is basis making no representation or warranty on the accuracy or completeness of the said information. We strongly encourage Users to check the accuracy and completeness of their information from time to time, and inform us immediately of any discrepancies, changes, or updates to such information. If Your Personal Information changes, You may correct, delete inaccuracies, or amend information by making the change on your Account or by contacting us through info@gizmo360.app. We will make good faith efforts to make requested changes in our then active databases as soon as 'reasonably practicable.’ `,
      `B) If You provide any information that is untrue, inaccurate, out of date or incomplete (or becomes untrue, inaccurate, out of date or incomplete), or Gizmo360 has reasonable grounds to suspect that the information provided by You is untrue, inaccurate, out of date or incomplete, Gizmo360 may, at its sole discretion, discontinue the provision of the services to you. There may be circumstances where Gizmo360 will not correct, delete or update Your Personal Information, including (a) where the Personal Information is opinion data which is kept solely for evaluative purpose; and (b) the Personal Information is in documents related to a prosecution if all proceedings relating to the prosecution have not been completed.`,
      `C) Gizmo360 may also display information for Users who have not Signed Up or registered for the services, provided that the Users have consented to Gizmo360 collecting, processing and/or disclosing their information on the Gizmo360. Such Users are verified by Gizmo360 or its associates, and Gizmo360 makes every effort to capture accurate information for such Users. However, Gizmo360 does not undertake any liability for any incorrect/incomplete information appearing on the Gizmo360 for such Users.`,
    ],
  },
  {
    sub_title: `V. PERMISSIONS:`,
    paragraphs: [
      `A)  Gizmo360 may seek permission from its Users to access Location, Contacts, Camera, Gallery, other social media platforms etc., for the purpose of facilitating better usage of Gizmo360.`,
    ],
  },
  {
    sub_title: `VI. COOKIES:`,
    paragraphs: [
      `A)  The Gizmo360 uses temporary cookies to collect and store certain information (that is not Sensitive Personal Data or Information) that is used by Gizmo360 and its service providers for the technical administration of the Gizmo360, research and development, and for User administration. In the course of serving advertisements or optimizing services to its Users, Gizmo360 may allow authorized third parties to place or recognise a unique cookie on the User’s browser. The cookies however, do not store any Personal Information of the User. You may adjust Your internet browser to disable cookies. If cookies are disabled, You may still use the Gizmo360, but the Gizmo360 may be limited in the use of some of the features.`,
    ],
  },
  {
    sub_title: `VII. SECURITY:`,
    paragraphs: [
      `A)  **Latest Data Protection Practices:** Gizmo360 has implemented best international market practices and security policies, rules and technical measures to protect the Personal Information that it has under its control from unauthorized access, improper use or disclosure, unauthorized modification and unlawful destruction or accidental loss. We further implement reasonable security practices and procedures and have a comprehensive documented information security programme and information security policies that contain managerial, technical, operational and physical security control measures that are commensurate with respect to the information being collected and the nature of Gizmo360 ’s business. However, for any data loss or theft due to unauthorized access to the User’s electronic devices through which the User avails the services, Gizmo360 shall not be held liable for any loss whatsoever incurred by the User.`,
      `B) **Disclosure clause:** Gizmo360 takes your right to privacy very seriously and other than as specifically stated in this Privacy Policy, will only disclose your Personal Information in the event of a requirement  to do so by any law, rule, regulation, law enforcement agency, governmental official, legal authority or similar mandates or when Gizmo360, in its sole discretion, deems it necessary in order to protect its rights or the rights of others, to prevent harm to persons or property, to fight fraud and credit risk, or to enforce or apply the Terms and Conditions.`,
      `C) If You have inadvertently submitted any such information to Gizmo360 prior to reading the privacy statements set out herein, and You do not agree with the manner in which such information is collected, processed, stored, used or disclosed, then You  may access, modify and delete such information by using options provided on the Gizmo360. In addition, You can, by sending an email to  **info@gizmo360.app**, inquire whether Gizmo360 is in possession of Your Personal Information, and You may also require Gizmo360 to delete and destroy all such information.`,
    ],
  },
  {
    sub_title: `VIII. DISCLAIMER:`,
    paragraphs: [
      `A)  This Privacy Policy applies to services that are owned and operated by Gizmo360. Gizmo360 does not exercise control over the sites displayed as search results or links from within its services. These other sites may place their own cookies or other files on the Users’ computer, collect data or solicit Personal Information from the Users, for which Gizmo360 is not responsible or liable. Accordingly, Gizmo360 does not make any representations concerning the privacy practices or policies of such third parties or Terms and Conditions of such Gizmo360s, nor does Gizmo360 guarantee the accuracy, integrity, or quality of the information, data, text, software, sound, photographs, graphics, videos, messages or other materials available on such other sites (“**Third Party Content**''). The inclusion or exclusion of any Third Party Content on Gizmo360 does not imply any endorsement by Gizmo360 of the Gizmo360, the Gizmo360's provider, or the information on the Gizmo360. If You decide to visit a third party Gizmo360 linked to the Gizmo360, You do this entirely at Your own risk. Gizmo360 encourages You to read the privacy policies of other third party Gizmo360s. `,
      `B) Gizmo360 may enable Users to communicate with other Users or to post information to be accessed by others, whereupon other Users may collect such data. Such Users, including any moderators or administrators, who are not authorized representatives or agents of Gizmo360, and their opinions or statements do not necessarily reflect those of Gizmo360, and they are not authorized to bind Gizmo360 to any contract. Gizmo360 hereby expressly disclaims any liability for any reliance or misuse of such information that is made available by Users or visitors in such a manner.`,
      `C) User(s) shall be solely responsible for information provided/ shared by them, through their Account and Gizmo360 shall not be responsible for unauthorized/illegal storage or usage of such information by other Users or by any third parties.`,
      `D) User(s) shall also ensure that they do not post or share any information on Gizmo360, that is, either prohibited by law or unauthorized by the owner(s) and/or author(s) and/or related parties of the information. Gizmo360 shall not be responsible for such unauthorized / illegal usage or sharing of information by the Users.`,
      `E) Gizmo360 shall not be liable for any kind of damages whether personal, monetary or reputational etc., caused to You, by other Users, any third parties or by Your own negligence and Gizmo360 shall not be proceeded or taken action against, in any such circumstances whatsoever.`,
      `F) After signing up with Gizmo360, You may choose to connect with other User(s) and make Connections by requesting to follow such other Users or accepting their request(s). Gizmo360 shall not be responsible for any unauthorised and/or illegal usage of Your personal information, that You chose to display/share in Your Profile, by Your Connections or other User(s) or for that you may send/receive based on the personal information displayed by You.`,
    ],
  },
  {
    sub_title: `IX. CONFIDENTIALITY AND SECURITY:`,
    paragraphs: [
      `A) Gizmo360 adheres to and commits to the protection of data and privacy of information collected from You. In respect of the information provided by or collected from You, the responsibility of Gizmo360 is restricted to that of an ‘intermediary’ under The Information Technology (Intermediary Guidelines and Digital Media Ethics Code) Rules, 2021. We collect, store, process, publish, alter, modify, delete, remove and use any information, in part or whole, collected from the Users including but not limited to all information provided in their respective Accounts, in accordance with this Privacy Policy. By using the Gizmo360 and/ or by downloading or accessing or Signing Up in Gizmo360 (as defined in the Privacy Policy), You consent to the collection and use of the information by Gizmo360, in accordance with the Privacy Policy;`,
      `B) Your Personal Information is maintained by Gizmo360 in electronic form on its equipment, and on the equipment of its employees. Such information may also be converted to physical form from time to time. Gizmo360 takes all necessary precautions to protect Your Personal Information both online and offline, and implements reasonable security practices and measures including certain managerial, technical, operational and physical security control measures that are commensurate with respect to the information being collected and the nature of the business undertaken by Gizmo360. Gizmo360 follows reasonable and best industry practices to prevent unauthorized access, use, alteration or destruction of Your Personal Information, in accordance with the SPI Rules.`,
      `C) No administrator at Gizmo360 will have knowledge of your password. It is important for You not to share Your password with anyone to protect Yourself against unauthorised access either through Your computer or Your mobile phone. You shall ensure that You logout from the mobile application/Gizmo360 upon during Your absence from the device in which Youmay use Gizmo360. Gizmo360 does not undertake any liability for any unauthorised use of Your Account and password. If You suspect any unauthorized use of Your Account, You must immediately notify Gizmo360 by sending an email to info@gizmo360.app. You shall be liable to indemnify Gizmo360 for any loss suffered by it due to such unauthorized use of Your Account and password.`,
      `D) Gizmo360 makes all User information accessible to its employees, agents or partners and third parties only on a necessity basis, and binds only its employees to strict confidentiality obligations.`,
      `E) Gizmo360 also aims to aid the Users to access information relating to them. Gizmo360 may, therefore, retain and submit, on a discretionary basis all such records to the relevant Users, upon a request.`,
      `F) All Gizmo360 employees and data processors, who have access to, and are associated with the processing of Sensitive Personal Data or Information, are obliged to respect the confidentiality of Users’ Personal Information or Sensitive Personal Data and Information. Gizmo360 has put in place procedures and technologies as per good industry practices and in accordance with the applicable laws, to maintain security of all Personal Information from the point of collection to the point of destruction. Any third-party data processor to which Gizmo360 transfers Personal Information shall have to agree to comply with those procedures and policies, or put in place adequate measures on their own.`,
      `G) Notwithstanding the above, Gizmo360 is not responsible for the confidentiality, security or distribution of Your Personal Information by our partners and third parties outside the scope of our agreement with such partners and third parties. Further, Gizmo360 shall not be responsible for any breach of security or for any actions of any third parties or events that are beyond the reasonable control of Gizmo360 including but not limited to, acts of government, computer hacking, unauthorised access to computer data and storage device, computer crashes, breach of security and encryption, poor quality of Internet service or telephone service of the User etc.`,
      `H) Gizmo360 strives to take reasonable security measures to protect Your personal information which are in its control. However, internet, computer systems, networks and resources are not fully secure. Gizmo360, therefore, does not guarantee the security of Your personal information. Gizmo360 will not be responsible for any breach of security of Your personal information caused either by Your negligence, or breach caused by other Users, Connections or any third parties (including third party sites) receiving Your personal information, or be liable for any loss / misuse in case of events happening beyond the control of Gizmo360 (including, but not limited to, fire, flood, earthquakes, rainstorms, cyclones, explosions, acts of God, civil commotion, riots, insurrection, war, acts of government, hacking and other kinds of cyber attacks, scraping, use of web crawlers, spiderbots, plug-in, add-ons, acts of government, unauthorised access to computer data and storage device, computer crashes, breach of security and encryption, poor quality of Internet service or telephone service of the User etc.)`,
      `K) Gizmo360 maintains a strict "No-Spam" policy, which means that Gizmo360 does not intend to sell, rent or otherwise give your e-mail address to a third party without your consent.`,
      `L)  No administrator at Gizmo360 will have knowledge of your password. Gizmo360 does not undertake any liability for any unauthorized use of your Account and password. If you suspect any unauthorized use of your Account, you must immediately notify Gizmo360 by sending an email to info@gizmo360.app. You shall be liable to indemnify Gizmo360 for any loss suffered by it due to such unauthorized use of your Account and password.`,
      `M) Gizmo360 makes all User information accessible to its employees, agents or partners and third parties only on a necessity basis, and binds only its employees to strict confidentiality obligations.`,
      `N) Gizmo360 may keep records of electronic communications and telephone calls received and made, for the purpose of administration of services, customer support, research and development.`,
    ],
  },
  {
    sub_title: `X. CHANGES TO PRIVACY POLICY:`,
    paragraphs: [
      `A)  Gizmo360 may update this Privacy Policy at any time, with or without advance notice. In the event that there are significant changes in the way, Gizmo360 treats User’s personally identifiable information, or in the Privacy Policy itself, Gizmo360 will display a notice on the Gizmo360 or send Users an email, as provided for above, so that You may review the changed terms prior to continuing to use the services. If You object to any of the changes to the terms of this Privacy Policy, and You no longer wish to use the services, You may deactivate Your Account or contact **info@gizmo360.app** to deactivate Your Account. Unless stated otherwise, the current Privacy Policy of Gizmo360 applies to all information that Gizmo360 has about You and Your Account.`,
      `B) If the User uses the services or accesses the Gizmo360 after a notice of changes has been sent to such User or published on the Gizmo360, such User hereby provides his/her/its consent to the changed terms.`,
    ],
  },
  {
    sub_title: `XI. CHILDREN'S AND MINOR'S PRIVACY:`,
    paragraphs: [
      `A) Gizmo360 will communicate with the Users through email, messages and notifications posted on Gizmo360 Account or through other means available through the service. The Users can change their e-mail and contact preferences at any time by logging into their "Account" at Gizmo360 and changing the Account settings.`,
    ],
  },
  {
    sub_title: `XII. GENERAL:`,
    paragraphs: [
      `A) Gizmo360 strongly encourages parents and guardians to supervise the online activities of their minor children and consider using parental control tools available from online services and software manufacturers to help provide a child-friendly online environment. These tools also can prevent minors from disclosing their name, address, and other personally identifiable information online without parental permission. Although Gizmo360  and its services are not intended for use by minors, Gizmo360 respects the privacy of minors who may inadvertently use the internet or the mobile application.`,
    ],
  },
  {
    sub_title: `XIII. YOUR CONSENT / CHOICE TO OPT-OUT:`,
    paragraphs: [
      `A) You acknowledge that this Privacy Policy is a part of the Terms and Conditions of Gizmo360, and You unconditionally agree that becoming a User of the Gizmo360 and its services signifies Your:`,
    ],
    bulletList: [
      { content: "assent to this Privacy Policy, and" },
      {
        content:
          "Personal Information in the manner and for the purposes set out in this Privacy Policy.",
      },
    ],
  },
  {
    sub_title: ``,
    paragraphs: [
      `B) You acknowledge that Your visit to the Gizmo360 and use of the services is subject to this Privacy Policy and the Terms and Conditions.`,
      `C) By using Gizmo360 and/or by providing Your information at the time of registration and any time thereafter, You consent to the collection and use of the information You disclose on Gizmo360 in accordance with this Privacy Policy. If You do not agree to Your Information being collected, used or shared in the manner set out in this Privacy Policy, You could either deactivate Your Account or inform Your withdrawal of consent by writing to or contacting the Grievance Redressal Officer as provided under Clause XVII of this Privacy Policy, but You may not be able to continue using the services of Gizmo360, after withdrawing Your Consent.`,
      `D) By deactivating Your Account, You agree that all the information including Personal Information, shall be retained by Gizmo360, as reasonably necessary to comply with our legal obligations, to resolve disputes regarding our Users, prevent fraud and abuse, enforce our agreements and/or protect our legitimate interests. In the vent that Your information is no longer required, we will ensure that it is securely deleted.`,
      `E) If You wish to opt-out of receiving non-essential communications such as promotional and marketing-related information regarding the services, please send us an email at info@gizmo360.app`,
    ],
  },
  {
    sub_title: `XIV. GOVERNING LAW AND DISPUTE RESOLUTION:`,
    paragraphs: [
      `A) This Privacy Policy shall be governed and construed under the laws of India, without any reference to its conflict of law rules and the Courts of Bengaluru, Karnataka, India shall have exclusive jurisdiction. In case of any disputes or differences between the Users and Gizmo 360, the same shall be resolved by Conciliation under Arbitration and Conciliation Act, 1996. If the said disputes or differences are not settled by Conciliation, the Parties shall resolve them by Arbitration before the sole arbitrator under Arbitration and Conciliation Act, 1996. The language of the arbitration proceedings shall be English and the seat shall be at Bengaluru, Karnataka, India.`,
    ],
  },
  {
    sub_title: `XV. GRIEVANCE REDRESSAL:`,
    paragraphs: [
      `A) In accordance with Information Technology Act, 2000 and the rules made thereunder, the name and contact details of the Grievance Officer are as follows,`,
      `**Grievance/Nodal Officer:** Mr. Anbu Chelian P E`,
      `M/s. Evolvum Tech Private Limited,`,
    ],
  },
  {
    sub_title: ``,
    paragraphs: [
      `**Address:**  No. 677, 1st Floor, Desk no: 105A, 27 Main, 13th Cross, HSR Layout, Sector 1, Bangalore, Karnataka - 560102.`,
      `**Email ID:** info@gizmo360.app`,
      `Lodge Your complaints at (portal address): [www.Gizmo360.app](https://www.Gizmo360.app/)`,
    ],
  },
];

const elements = generateReactCode(content);

const PrivacyPolicy = () => {
  return (
    <div className="max-w-7xl mx-auto break-words px-8">
      {elements}
      <div className="mb-24"></div>
    </div>
  );
};

export default PrivacyPolicy;
