import React from "react";
import ReactMarkdown from "react-markdown";

function convertNestedText(content) {
  if (typeof content === "string") {
    return content.split("`").map((text, index) => {
      if (index % 2 === 0) {
        return text;
      } else {
        return (
          <span key={`nested-text-${index}`}>
            {text.split(" ").map((word, wordIndex) => (
              <React.Fragment key={`nested-word-${index}-${wordIndex}`}>
                {wordIndex > 0 && " "}
                <strong className="font-bold">{word}</strong>
              </React.Fragment>
            ))}
          </span>
        );
      }
    });
  }

  if (Array.isArray(content)) {
    return content.flatMap(convertNestedText);
  }

  return content;
}

function generateReactCodeEngine(content) {
  const elements = [];

  const renderParagraph = (paragraph, index) => (
    <p
      key={`paragraph-${index}`}
      className="mt-6 text-base font-normal leading-7 text-gray-700 font-pj"
    >
      <ReactMarkdown
        components={{
          a: ({ href, children }) => (
            <a
              href={href}
              className="text-accentOrange"
              target="_blank"
              rel="noopener noreferrer"
            >
              {children}
            </a>
          ),
          strong: ({ children }) => (
            <strong className="font-bold">{children}</strong>
          ),
        }}
      >
        {paragraph}
      </ReactMarkdown>
    </p>
  );

  const mdx = (content) => (
    <ReactMarkdown
      components={{
        a: ({ href, children }) => (
          <a
            href={href}
            className="text-accentOrange"
            target="_blank"
            rel="noopener noreferrer"
          >
            {children}
          </a>
        ),
        strong: ({ children }) => (
          <strong className="font-bold">{children}</strong>
        ),
      }}
    >
      {content}
    </ReactMarkdown>
  );

  const renderHeading = (heading, index) => (
    <h3
      key={`heading-${index}`}
      className="mt-6 text-lg font-bold text-gray-900 font-pj"
    >
      {heading}
    </h3>
  );

  const renderList = (list, index) => {
    const renderListItem = (item, itemIndex) => (
      <li key={`list-item-${itemIndex}`} className="py-2" >{mdx(item)}</li>
    );

    return (
      <ol
        key={`list-${index}`}
        className="list-decimal ml-3 mt-4 text-base font-normal leading-7 text-gray-700 font-pj"
      >
          <span>{list.map(renderListItem)}</span>
      </ol>
    );
  };

  const renderBulletList = (bulletList) => (
    <ul className="list-disc list-inside ml-4 mt-2 text-base font-normal leading-7 text-gray-700 font-pj">
      {bulletList.map((item, index) => (
        <li key={`bullet-item-${index}`}>{item.content}</li>
      ))}
    </ul>
  );

  content.forEach((section) => {
    for (const key in section) {
      if (key === "title") {
        elements.push(
          <h2 className="mt-16 text-3xl font-bold text-gray-900 font-pj text-center">
            {section[key]}
          </h2>
        );
      } 
     else if (key === "sub_title") {
        elements.push(
          <h3 className="mt-6 text-lg font-bold text-gray-900 font-pj">
            {section[key]}
          </h3>
        );
      } 
      else if (key === "space") {
        const spaceHeight = section[key] * 8;
        elements.push(
          <div
            key={`space-${elements.length}`}
            style={{ height: `${spaceHeight}px`, width: "100%" }}
          />
        );
      } else if (key === "paragraphs") {
        section[key].forEach((paragraph, index) => {
          elements.push(renderParagraph(paragraph, index));
        });
      } else if (key === "lists") {
        section[key].forEach((list, index) => {
          elements.push(renderList(list.content, index));
        });
      } else if (key === "bulletList") {
        elements.push(renderBulletList(section[key]));
      }
    }
  });

  return elements;
}

function generateReactCode(content) {
  const elements = generateReactCodeEngine(convertNestedText(content));

  return (
    <div className="container mx-auto mt-8">
      {elements.map((element, index) => (
        <React.Fragment key={`element-${index}`}>{element}</React.Fragment>
      ))}
    </div>
  );
}

export { generateReactCode };
