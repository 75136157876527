import { motion as m } from "framer-motion"
import React from "react"

// assets
import slider1 from "../../assets/images/home/slider1.webp"
import slider2 from "../../assets/images/home/slider2.webp"
import slider3 from "../../assets/images/home/slider3.svg"
import slider4 from "../../assets/images/home/slider4.webp"
// import slider5 from "../../assets/images/home/slider5.webp"
import slider6 from "../../assets/images/home/slider6.webp"
import slider7 from "../../assets/images/home/slider7.webp"
import slider8 from "../../assets/images/home/sliderNew.svg"

// import SliderCard from "./SliderCard"

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react"

import { Autoplay, Pagination } from "swiper"

// Import Swiper styles
import "swiper/css"
import "swiper/css/pagination"

const projects = [
    {
        src: slider1
    },
    {
        src: slider2
    },
    {
        src: slider3
    },
    {
        src: slider4
    },
    {
        src: slider7
    },
    {
        src: slider6
    },
    {
        src: slider8
    }
]

const variants = {
    visible: { opacity: 1, y: 0 },
    hidden: { opacity: 0, y: 20 }
}

const Review = () => {
    return (
        <section
            id="feature"
            className="bg-white pb-20  pt-28 relative overflow-hidden"
        >
            <div className="">
                <div className=" px-4 text-center mx-auto max-w-3xl ">
                    <m.h2
                        initial="hidden"
                        whileInView="visible"
                        // viewport={{ once: true }}
                        transition={{ duration: 0.4 }}
                        variants={variants}
                        className="text-3xl font-bold text-gray-900 sm:text-4xl xl:text-5xl xll:text-7xl"
                    >
                        Unlock a smarter way to shop
                    </m.h2>
                    <m.p
                        initial="hidden"
                        whileInView="visible"
                        // viewport={{ once: true }}
                        transition={{ duration: 0.4, delay: 0.2 }}
                        variants={variants}
                        className=" my-4 text-lg md:text-xl font-normal text-gizmoBlack xll:text-2xl xll:mt-8"
                    >
                        Explore the ultimate shopping experience with Gizmo360 - sign up today and discover its
                        features.
                    </m.p>
                </div>

                <div className="w-[70%] mx-auto  xll:mt-40 relative">
                    <Swiper
                        className="!overflow-visible !pb-4"
                        modules={[Pagination, Autoplay]}
                        // spaceBetween={0}
                        // slidesPerView={4}
                        loop
                        autoplay={{
                            delay: 3000,
                            disableOnInteraction: false
                        }}
                        breakpoints={{
                            360: {
                                slidesPerView: 1,
                                spaceBetween: 10
                            },
                            768: {
                                slidesPerView: 2,
                                spaceBetween: 50
                            },
                            1024: {
                                slidesPerView: 3,
                                spaceBetween: 50
                            },
                            1440: {
                                slidesPerView: 3,
                                spaceBetween: 50
                            },
                            1500: {
                                slidesPerView: 3,
                                spaceBetween: 50
                            }
                        }}
                        centeredSlides
                        pagination={{ clickable: false }}
                        // onSlideChange={() => console.log("slide change")}
                        // onSwiper={(swiper) => console.log(swiper)}
                    >
                        {projects.map((project, idx) => (
                            <SwiperSlide
                                key={idx}
                                className="mySwiper"
                            >
                                {({ isActive }) => (
                                    // <div
                                    //     className={` ${
                                    //         isActive
                                    //             ? "brightness-100 h-[400px] mt-12 md:h-[600px] lg:h-[685px] xll:h-[900px]"
                                    //             : "brightness-50 h-[330px] xs:h-[350px] md:h-[450px] lg:h-[580px] xll:h-[800px] mt-24 xs:mt-20 md:mt-20  "
                                    //     } relative block overflow-hidden `}
                                    // >
                                    //     <img
                                    //         className={` absolute w-full h-full ${
                                    //             isActive ? "object-contain" : "object-contain"
                                    //         } inset-0 transition-all ease-in`}
                                    //         src={project.src}
                                    //         alt=""
                                    //     />
                                    // </div>
                                    <div
                                        className={` ${
                                            isActive
                                                ? "brightness-100 h-[500px] md:h-[600px] lg:h-[685px] xl:h-[685px] xll:h-[900px]"
                                                : "brightness-50 h-[260px] xs:h-[280px] md:h-[450px] lg:h-[380px] xl:h-[500px] xll:h-[860px] mt-36 xs:mt-32 md:mt-20 lg:mt-40 xl:mt-24"
                                        } relative block overflow-hidden `}
                                    >
                                        <img
                                            className={` absolute w-full h-full ${
                                                isActive ? "object-contain" : "object-contain"
                                            } inset-0 transition-all ease-in`}
                                            src={project.src}
                                            alt=""
                                        />
                                    </div>
                                )}
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </div>
        </section>
    )
}

export default Review
