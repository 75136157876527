import { motion as m } from "framer-motion"
import React from "react"


// assets
import { useNavigate } from "react-router-dom"
import logo from "../../assets/logo/logo-sm.svg"

const Header = () => {
    const [menuOpened, setMenuOpened] = React.useState(false)
    const navigate = useNavigate();
    const scrollTo = (id) => {
      if (window.location.pathname === "/") {
        document.getElementById(id).scrollIntoView({ behavior: "smooth" });
      } else {
        navigate("/");
        setTimeout(() => {
          document.getElementById(id).scrollIntoView({ behavior: "smooth" });
        }, 100);
      }
    };

    return (
      <m.header
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 50 }}
        transition={{ duration: 0.4, delay: 0.3 }}
        className="bg-white border-b sticky z-40 top-0 shadow-md "
      >
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          {/* <!-- lg+ --> */}
          <nav className="flex items-center xll:scale-[1.3] justify-between h-16 lg:h-20">
            <div className="flex-shrink-0">
              <a href="/" title="" className="flex">
                <img className="w-auto h-10 lg:h-12" src={logo} alt="logo" />
              </a>
            </div>

            <button
              onClick={() => setMenuOpened(!menuOpened)}
              type="button"
              className="inline-flex p-2 text-black transition-all duration-200 rounded-md md:hidden hover:bg-accentOrange hover:text-white"
            >
              <svg
                className="w-6 h-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                />
              </svg>
            </button>

            <div className="hidden md:flex md:items-center md:gap-5 lg:gap-10">
              <p
                onClick={() => scrollTo("home")}
                title=""
                className="cursor-pointer text-sm font-normal text-black transition-all duration-200 lg:text-base hover:text-accentOrange focus:text-opacity-70"
              >
                {" "}
                Home
              </p>
              <p
                onClick={() => scrollTo("feature")}
                title=""
                className="cursor-pointer text-sm font-normal text-black transition-all duration-200 lg:text-base hover:text-accentOrange focus:text-opacity-70"
              >
                {" "}
                Features{" "}
              </p>

              <p
                onClick={() => scrollTo("gizmo")}
                title=""
                className="cursor-pointer text-sm font-normal text-black transition-all duration-200 lg:text-base hover:text-accentOrange focus:text-opacity-70"
              >
                {" "}
                Why GIZMO360?
              </p>

              <p
                onClick={() => scrollTo("faq")}
                title=""
                className="cursor-pointer text-sm font-normal text-black transition-all duration-200 lg:text-base hover:text-accentOrange focus:text-opacity-70"
              >
                {" "}
                FAQ
              </p>

              <p
                onClick={() => scrollTo("testimonial")}
                title=""
                className="cursor-pointer text-sm font-normal text-black transition-all duration-200 lg:text-base hover:text-accentOrange focus:text-opacity-70"
              >
                {" "}
                Testimonials
              </p>
            </div>

            <a
              // href="mailto:info@gizmo360.in"
              href="https://gizmo360.freshdesk.com/support/home"
              target="_blank"
              className="items-center justify-center hidden px-6 py-3 text-sm lg:text-base font-semibold text-white transition-all duration-200 bg-accentOrange border border-transparent rounded-lg md:inline-flex"
              role="button"
              rel="noreferrer"
            >
              Contact Us
            </a>
          </nav>

          {/* <!-- xs to lg --> */}
          <nav
            className={`${
              menuOpened
                ? "min-h-[calc(100vh-64px)] px-2 py-10"
                : "h-0 overflow-hidden"
            }  text-center bg-white md:hidden`}
          >
            <nav className="flex flex-col items-start  space-y-2">
              <p
                onClick={() => {
                  setMenuOpened(false);
                  scrollTo("home");
                }}
                title=""
                className="cursor-pointer py-2 font-semibold text-2xl text-black transition-all duration-200 focus:text-opacity-70"
              >
                {" "}
                Home{" "}
              </p>
              <p
                onClick={() => {
                  setMenuOpened(false);
                  scrollTo("feature");
                }}
                title=""
                className="cursor-pointer py-2 font-semibold text-2xl text-black transition-all duration-200 focus:text-opacity-70"
              >
                {" "}
                Features{" "}
              </p>

              <p
                onClick={() => {
                  setMenuOpened(false);
                  scrollTo("gizmo");
                }}
                title=""
                className="cursor-pointer py-2 font-semibold text-2xl text-black transition-all duration-200 focus:text-opacity-70"
              >
                {" "}
                Why GIZMO360?{" "}
              </p>

              <p
                onClick={() => {
                  setMenuOpened(false);
                  scrollTo("faq");
                }}
                title=""
                className="cursor-pointer py-2 font-semibold text-2xl text-black transition-all duration-200 focus:text-opacity-70"
              >
                {" "}
                FAQ{" "}
              </p>

              <p
                onClick={() => {
                  setMenuOpened(false);
                  scrollTo("testimonial");
                }}
                title=""
                className="cursor-pointer py-2 font-semibold text-2xl text-black transition-all duration-200 focus:text-opacity-70"
              >
                {" "}
                Testimonials{" "}
              </p>
            </nav>
          </nav>
        </div>
      </m.header>
    );
}

export default Header
