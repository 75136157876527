import { BrowserRouter } from "react-router-dom"
import Router from "./routers/Router"
import Layout from "./layout/index"
import { AnimatePresence } from "framer-motion"

function App() {
    return (
        <BrowserRouter>
            <AnimatePresence>
                <Layout>
                    <Router />
                </Layout>
            </AnimatePresence>
        </BrowserRouter>
    )
}

export default App
