import React from 'react'
import {BsCheck2Circle} from 'react-icons/bs'

function index() {
  return (
    <div  className="flex items-center justify-center">
        <div className='rounded-lg border-2  border-[#ddffe0] border-solid w-[800px] py-2  flex flex-col items-center justify-center my-[15%]'>
        <BsCheck2Circle  size={58} color='#8CEC94' className='my-3'/>

        <h1 className=' text-2xl px-4 font-medium my-3'>You'll be successfully unSubscribed from the selected channels within 24 hours.</h1>

        </div>
    </div>
  )
}

export default index