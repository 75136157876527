import React from "react"
import { motion as m } from "framer-motion"

// assets
import members from "../../assets/images/home/Members.webp"

const variants = {
    visible: { opacity: 1, y: 0 },
    hidden: { opacity: 0, y: 20 }
}

const Creators = () => {
    return (
        <section className="pb-28 pt-40 lg:pt-40 bg-[#FEF8F5] xll:pb-60">
            <div className="mx-auto max-w-[106rem] sectionPadding">
                <div className="grid items-center grid-cols-1 md:grid-cols-2 gap-y-12 md:gap-x-16 xl:gap-x-36">
                    <m.div
                        initial="hidden"
                        whileInView="visible"
                        // viewport={{ once: true }}
                        transition={{ duration: 0.4, delay: 0.2 }}
                        variants={variants}
                        className=" order-2"
                    >
                        <img
                            className="w-full max-w-sm mx-auto"
                            src={members}
                            alt="members"
                        />
                    </m.div>

                    <div className="text-center md:order-1 md:text-left">
                        <m.h2
                            initial="hidden"
                            whileInView="visible"
                            // viewport={{ once: true }}
                            transition={{ duration: 0.4 }}
                            variants={variants}
                            className="text-3xl font-bold  text-gray-900 sm:text-4xl xl:text-5xl font-pj"
                        >
                            Powered by Creators
                        </m.h2>
                        <m.p
                            initial="hidden"
                            whileInView="visible"
                            // viewport={{ once: true }}
                            transition={{ duration: 0.4, delay: 0.2 }}
                            variants={variants}
                            className="text-lg font-medium text-gray-900 mt-7 font-pj"
                        >
                            Tired of feeling lost in the sea of electronics options?
                        </m.p>
                        <m.p
                            initial="hidden"
                            whileInView="visible"
                            // viewport={{ once: true }}
                            transition={{ duration: 0.4, delay: 0.3 }}
                            variants={variants}
                            className="mt-5 text-base font-normal text-gray-700"
                        >
                            Join Gizmo 360 and let our community of creators guide you towards making informed decisions
                            with confidence. With their expertise, you can say goodbye to confusion and uncertainty and
                            hello to elevated electronics game!
                        </m.p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Creators
