import { motion as m } from "framer-motion"
import React, { useEffect, useState } from "react"

// assets
import heroSectionImg from "../../assets/images/home/hero_image.png"
import TopPopup from "../../components/Popup/TopModal"

// components


const Hero = () => {
    const [showModal, setShowModal] = useState(false)

    useEffect(() => {
        if (showModal) {
            document.body.style.overflow = "hidden"
            return
        } else {
            document.body.style.overflow = "unset"
            return
        }
    }, [showModal])

    return (
        <>
            <TopPopup
                showModal={showModal}
                setShowModal={setShowModal}
            />
            <section
                id="home"
                className="pt-12 min-h-screen md:min-h-[60vh] lg:min-h-screen overflow-x-hidden bg-primaryBg relative  sm:pt-16 lg:pt-20 xl:pt-24"
            >
                <div className=" mx-auto sectionPadding max-w-[106rem">
                    <div className="max-w-6xl mx-auto text-center">
                        <m.h1
                            initial={{ opacity: 0, y: 10 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 0.6, ease: "easeIn" }}
                            className="text-4xl font-bold tracking-tight text-black sm:text-5xl xl:text-6xl xll:text-7xl sm:tracking-tight"
                        >
                            Transform Your Tech Hunt
                        </m.h1>
                        <m.p
                            initial={{ opacity: 0, y: 10 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 0.6, ease: "easeIn", delay: 0.2 }}
                            className="mt-4 max-w-2xl xll:max-w-3xl mx-auto text-lg leading-7 text-[#616C87] lg:leading-8 lg:text-xl xll:text-2xl xll:mt-8"
                        >
                            Stay ahead of the curve with the latest tech from top brands, gain expert insights, and shop
                            with confidence.
                        </m.p>
                        <m.div
                            initial={{ opacity: 0, y: 10 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 0.6, ease: "easeIn", delay: 0.3 }}
                            className="mt-10 xll:mt-12"
                        >
                            <button
                                onClick={() => setShowModal(true)}
                                title=""
                                className="inline-flex items-center justify-center px-6 py-3 xll:px-10 xll:py-6 text-base xll:text-xl font-medium text-white transition-all duration-200 bg-accentOrange border border-transparent rounded-lg shadow-md shadow-accentOrange/40"
                            >
                                Sign Up for Early Access
                            </button>
                        </m.div>
                    </div>
                </div>

                <img
                    src={heroSectionImg}
                    alt=""
                    className="w-full min-w-[800px] left-1/2 -translate-x-1/2 mt-20 z-10 block relative "
                />
                {/* <SmoothImage
                    src={heroSectionImg}
                    alt="heroSectionMobileImage"
                    transitionTime={0.6}
                    className="w-full min-w-[800px] left-1/2 -translate-x-1/2 mt-20 z-10 block relative "
                    //Other props if you choose to use them
                /> */}
                <div className="absolute w-full bg-creameBg h-20  lg:h-32 bottom-0"></div>
            </section>
        </>
    )
}

export default Hero
