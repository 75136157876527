import React from "react"
import { motion as m } from "framer-motion"

// assets
import logo from "../../assets/logo/logo-sm.svg"
import gizmoMobile from "../../assets/images/home/gizmoMobile.webp"
import ellipse from "../../assets/images/home/ellipse.webp"
import founderImage from "../../assets/images/home/founder.webp"
import appleImg from "../../assets/images/footer/apple-store.svg"
import googleImg from "../../assets/images/footer/google-play.svg"

const variants = {
    visible: { opacity: 1, y: 0 },
    hidden: { opacity: 0, y: 20 }
}

const ImageContent = ({ section }) => {
    return (
        <section
            className={`py-20 ${
                section === "founder" ? " bg-white" : " bg-creameBg"
            }  lg:py-32 xll:py-60   overflow-hidden`}
        >
            <div className="max-w-[106rem] sectionPadding mx-auto">
                <div className="grid gap-y-10 md:grid-cols-2 gap-x-9 lg:gap-x-20 items-center">
                    <m.div
                        initial="hidden"
                        whileInView="visible"
                        // viewport={{ once: true }}
                        transition={{ duration: 0.4, delay: 0.2 }}
                        variants={variants}
                        className={`relative h-[320px] grid  max-sm:gap-8 place-items-center lg:h-[540px] `}
                    >
                        {!section ? (
                            <div className="absolute w-[280px] h-[280px]  lg:w-[420px] xll:w-[500px] xll:h-[500px] lg:h-[420px]">
                                <img
                                    className="image"
                                    src={ellipse}
                                    alt=""
                                />
                            </div>
                        ) : (
                            ""
                        )}
                        <img
                            className="image !object-contain xll:scale-[1.2]"
                            src={section ? founderImage : gizmoMobile}
                            alt="logoBig"
                        />
                    </m.div>

                    <div className="flex flex-col justify-center items-start max-sm:mt-10 xl:px-16 xl:pr-0">
                        {section === "founder" ? (
                            <m.img
                                initial="hidden"
                                whileInView="visible"
                                // viewport={{ once: true }}
                                transition={{ duration: 0.4, delay: 0.2 }}
                                variants={variants}
                                className="mb-4 w-[150px] xll:w-[250px]"
                                src={logo}
                                alt="logo"
                            />
                        ) : (
                            <m.h2
                                initial="hidden"
                                whileInView="visible"
                                // viewport={{ once: true }}
                                transition={{ duration: 0.4 }}
                                variants={variants}
                                className="text-3xl font-bold xll:text-7xl leading-tight text-black sm:text-4xl"
                            >
                                Don't just shop
                            </m.h2>
                        )}
                        {section === "founder" ? (
                            <m.p
                                initial="hidden"
                                whileInView="visible"
                                // viewport={{ once: true }}
                                transition={{ duration: 0.4, delay: 0.2 }}
                                variants={variants}
                                className="mt-4 text-2xl text-black xll:text-2xl"
                            >
                                We're all about connecting tech enthusiasts with the latest products and expert
                                insights, to create a more informed and connected community.
                            </m.p>
                        ) : (
                            <m.p
                                initial="hidden"
                                whileInView="visible"
                                // viewport={{ once: true }}
                                transition={{ duration: 0.4, delay: 0.2 }}
                                variants={variants}
                                className="mt-4 text-base leading-relaxed  xll:mt-8
                            text-black xll:text-2xl"
                            >
                                Immerse yourself in the world of consumer tech with Gizmo 360 Social Shopping - your
                                gateway to innovation and expertise!
                            </m.p>
                        )}

                        {section === "founder" ? (
                            <m.p
                                initial="hidden"
                                whileInView="visible"
                                // viewport={{ once: true }}
                                transition={{ duration: 0.4, delay: 0.2 }}
                                variants={variants}
                                className=" mt-6 text-base xll:text-xl font-medium"
                            >
                                Anbu Chelian {"  "}
                                <span className=" font-normal text-[#52525B]"> Founder & CEO at GIZMO</span>{" "}
                            </m.p>
                        ) : (
                            <m.div
                                initial="hidden"
                                whileInView="visible"
                                // viewport={{ once: true }}
                                transition={{ duration: 0.4, delay: 0.2 }}
                                variants={variants}
                                className="grid grid-cols-2 xll:mt-12 w-[85%] xll:w-1/2 gap-4 mt-6"
                            >
                                <img
                                    className="w-full"
                                    src={appleImg}
                                    alt=""
                                />
                                <img
                                    className="w-full"
                                    src={googleImg}
                                    alt=""
                                />
                            </m.div>
                        )}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ImageContent
