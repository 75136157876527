// ** import pages
// import AboutUs from "../../pages/AboutUs"
import PrivacyPolicy from "../../pages/PrivacyPolicy";
import TermsConditions from "../../pages/TermsConditions";
import UnsubscribePage from "../../pages/UnsubscribePage";
import Home from "./../../pages/Home";
// import Services from "./../../pages/Services"
// import Works from "./../../pages/Works"
// import ContactUs from "./../../pages/ContactUs"
import Success from '../../components/success'

const pages = [
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicy />,
  },
  {
    path: "/terms-conditions",
    element: <TermsConditions />,
  },
  // {
  //     path: "/about-us",
  //     element: <AboutUs />
  // },
  // {
  //     path: "/contact-us",
  //     element: <ContactUs />
  // }

  {
        path: "/UnSubscribe",
        element: <UnsubscribePage />
    },
    {
      path: "/success",
      element: <Success />
  }
];

export default pages;
